import React, { useState } from 'react'
import { Container, ListGroup, ListGroupItem } from 'react-bootstrap';



function SelectBrand({ selectedBrand, setSelectedBrand ,brands}) {

    const handleBrandClick = (brand) => {
        setSelectedBrand(brand)
    }

    return (
        <Container style={{ marginBottom: "40px",width:"100%" }}>
                        <ListGroup>

          
                {brands.map((brand) => (
                        <ListGroupItem
                        key={brand.key}
                            onClick={() => handleBrandClick(brand)}
                            className={selectedBrand?.key === brand.key ? 'bg-primary text-white' : 'bg-light'}
                            style={{ cursor: 'pointer', margin: '3px' }}
                        >
                          <img
                            src={brand.logoUrl}
                            alt={brand.name}
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '10px',
                                borderRadius: '50%',
                            }}
                        />
                        {brand.name}
                        </ListGroupItem>
                ))}
 
            </ListGroup>

        </Container>
    )
}

export default SelectBrand
