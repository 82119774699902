import {createSlice} from "@reduxjs/toolkit";
// import cars from "../../data/data";
import {fetchAllVehicles,
  addVehicle,
  fetchVehiclesByCompany,
  findVehicleByVin,
  findVehicleById,
  getAllVehiclesByUserId
} from './actions'
import cars from "../../data/data.json"





const carSlice = createSlice({
    name : "car",
    initialState : {
        allCars: cars.scappamCars,
        savedCar: null,
        events: [],
        enodeAccessToken: null,
        enodeAccessLink: null,
        markesList: [],
        modelsList: [],
        selectedMake: null,
        selectedModel:null,
        energyProvider: null,
        supported: false,
        btnDisabled : false,
        address:'',
        selectedCarProfile : [],
        prefenreceDefaultValue: {
             batteryLevel: 80,
             availabilityTime : "06:00",
            
        },
        inergieProviderDefaultValue : {
            offPeakCost: 0.1227,
            peakCost: 0.156,
            priceKwh: 2,
            startPeakHour : "22:00",
            endPeakHour : "06:00",
            superStartOffPeakHour: "22:00",
            superEndOffPeakHour: "06:00",
            superOffPeakCost: 0.1227,
            enedisEnabled : false
       }
    },
    reducers : {
      setAllCars: (state, action) => {
        state.allCars = action.payload;
      },
      editCars: (state, action) => {

        const carid = action.payload.car.id;
        const updatedCars = state.allCars;
        const carIndex = updatedCars.findIndex((car) => carid === car.id);
       const newCar = {...updatedCars[carIndex]};
       newCar.currentUser = action.payload.driver;
      //  newCar.assigned = action.payload.driver.name;
       updatedCars[carIndex] = newCar;
       state.allCars = updatedCars;

  
      },
      setSelectedCarProfile : (state,action) => {
        state.selectedCarProfile = action.payload
      }
    },
    extraReducers: {
    
     

      [fetchAllVehicles.fulfilled]: (state, { payload }) => {
        state.allCars = payload.allCars;
      },
      [addVehicle.fulfilled]: (state, { payload }) => {
        // Handle successful addVehicle action
      },
      [fetchVehiclesByCompany.fulfilled]: (state, { payload }) => {

        // state.selectedCarProfile = payload.selectedCarProfile;
      
      },
      [findVehicleByVin.fulfilled]: (state, { payload }) => {
        // Handle successful findVehicleByVin action
      },
      [findVehicleById.fulfilled]: (state, action) => {
       // Handle successful findVehicleById action
  console.log("fulfilled", action);


// state.selectedCarProfile = action.payload
      },
      [findVehicleById.pending]: (state, action) => {
        console.log("pending", action);
      },
      [findVehicleById.rejected]: (state, action) => {
       console.log("rejected",action)
      },
      [getAllVehiclesByUserId.fulfilled]: (state, { payload }) => {
        // Handle successful getAllVehiclesByUserId action
      },
    },
})
export const {setAllCars,editCars,setSelectedCarProfile} = carSlice.actions;

export default carSlice;