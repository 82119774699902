import { useState, useEffect, memo, Fragment } from 'react'
import { Row, Col, Dropdown, Button, Form, Table, Card, Tab, Nav,ListGroup, ListGroupItem ,Image} from 'react-bootstrap'

import Chart from "react-apexcharts";
import AdCard1 from '../../views/modules/e-commerce/components/ad-card1'

import CustomToggle from '../dropdowns'

import { useSelector } from 'react-redux';

import * as SettingSelector from '../../store/setting/selectors'

//Count-up






const ChartsAndCards = memo((props) => {
    useSelector(SettingSelector.theme_color)

    const getVariableColor = () => {
        let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
        if (prefix) {
            prefix = prefix.trim()
        }
        const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
        const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
        const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
        const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
        return {
            primary: color1.trim(),
            info: color2.trim(),
            warning: color4.trim(),
            primary_light: color3.trim(),
        };
    }
    
    const variableColors = getVariableColor();
    const [checked, setChecked] = useState(true);
    const [chartTitle,setChartTitle] = useState("Total charging cost")
    const colors = [variableColors.primary, variableColors.info];
    const chartColors = [variableColors.primary, "#858787","#6103f2"];

    const [selectedperiod,setSelectedPeriod] = useState("Month")
    const [selectedCard,setSelectedCard] = useState(1);
    const chartOptions = {
      colors: chartColors,
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
    
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return '$' + val; // Add "$" to the value labels
        },
      },
    },
      
      };
    const [selectedChartData,setSelectedChartData] = useState([
      {
        name: "Home",
        data: props.isDriver ? [81, 86, 76, 73, 78, 70, 92, 87, 76, 53] :[810, 811, 762, 722, 783, 704, 925, 879, 760, 530],
      },
      {
          name: "Work",
          data: props.isDriver ? [11, 16, 26, 13, 18, 10, 0, 0, 16, 6] : [510, 462, 462, 531, 882, 803, 570, 370, 561, 361],
        },
        {
          name: "Road",
          data: props.isDriver ? [0,0,0,0,0,0,0,0,0,0] :[150, 145, 121, 158, 287, 145, 340, 365, 140, 50],
        },
      ])

    useEffect(() => {
        return () => colors
    })

    const handleSelectingCard = (number) =>{
      console.log(chartColors)
        setSelectedCard(number)
        if (number === 1) {
            setChartTitle("Total charging cost")
            setSelectedChartData([
                {
                  name: "Home",
                  data: props.isDriver ? [81, 86, 76, 73, 78, 70, 92, 87, 76, 53] :[810, 811, 762, 722, 783, 704, 925, 879, 760, 530],
                },
                {
                    name: "Work",
                    data: props.isDriver ? [11, 16, 26, 13, 18, 10, 0, 0, 16, 6] : [510, 462, 462, 531, 882, 803, 570, 370, 561, 361],
                  },
                  {
                    name: "Road",
                    data: props.isDriver ? [0,0,0,0,0,0,0,0,0,0] :[150, 145, 121, 158, 287, 145, 340, 365, 140, 50],
                  },
              ])
        }
        else if (number === 2) {
            setChartTitle("Total energy consumption")
            setSelectedChartData( [
                {
                  name: "Home",
                  data: props.isDriver ?[382, 351, 334, 350, 344, 351, 389, 392, 340, 290] : [3820, 3510, 3340, 3500, 3440, 3510, 3890, 3920, 3400, 2900],
                },
                { 
                  name: "Work",
                  data: props.isDriver ?[62, 65, 70, 60, 67, 58, 0, 0, 64, 35] : [6211, 6522, 7031, 6012, 6751, 5862, 8701, 7504, 6415, 3536],
                },
                {
                  name: "Road",
                  data: props.isDriver ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]: [3820, 3510, 3340, 3500, 3440, 3510, 3890, 3920, 3400, 2900]
                }
              ]
              )
        }
        else if (number === 3) {
            setChartTitle("Total CO2 emission")
         
                setSelectedChartData( [
                    {
                      name: "Home",
                      data: [67, 72, 69, 28, 95, 80],
                    },
                    {
                      name: "Work",
                      data: [92, 84, 78, 89, 96, 82],
                    },
                    {
                      name: "Road",
                      data: [53, 49, 62, 71, 66, 58],
                    }
                  ]
                  
                  )
              
        }
        else {
            setChartTitle("Total CO2 avoided")
            setSelectedChartData( [
                {
                  name: "Home",
                  data: [62, 58, 54, 61, 65, 59],
                },
                {
                  name: "Work",
                  data: [87, 95, 89, 92, 86, 94],
                },
                {
                  name: "Road",
                  data: [43, 48, 55, 51, 46, 52],
                }
              ]
              
              )
        }

    };

    const svg1 = <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32"><path opacity="0.4" d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z" fill="currentColor"></path><path d="M7.36866 9.3689C6.91533 9.3689 6.54199 9.74223 6.54199 10.2045V17.0756C6.54199 17.5289 6.91533 17.9022 7.36866 17.9022C7.83088 17.9022 8.20421 17.5289 8.20421 17.0756V10.2045C8.20421 9.74223 7.83088 9.3689 7.36866 9.3689Z" fill="currentColor"></path><path d="M12.0352 6.08887C11.5818 6.08887 11.2085 6.4622 11.2085 6.92442V17.0755C11.2085 17.5289 11.5818 17.9022 12.0352 17.9022C12.4974 17.9022 12.8707 17.5289 12.8707 17.0755V6.92442C12.8707 6.4622 12.4974 6.08887 12.0352 6.08887Z" fill="currentColor"></path><path d="M16.6398 12.9956C16.1775 12.9956 15.8042 13.3689 15.8042 13.8312V17.0756C15.8042 17.5289 16.1775 17.9023 16.6309 17.9023C17.0931 17.9023 17.4664 17.5289 17.4664 17.0756V13.8312C17.4664 13.3689 17.0931 12.9956 16.6398 12.9956Z" fill="currentColor"></path></svg>
    
    const svg2 =<svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32"><path opacity="0.4" d="M4.29415 14.6675L14.9349 2.63879C15.075 2.48048 15.3329 2.61934 15.2778 2.82341L13.1395 10.7479C13.1052 10.875 13.2009 11 13.3326 11H19.0266C19.2034 11 19.2933 11.2126 19.17 11.3394L8.40053 22.4166C8.2548 22.5665 8.0058 22.4205 8.06544 22.2201L10.1378 15.2571C10.1759 15.1288 10.0799 15 9.94609 15H4.44395C4.2716 15 4.17996 14.7966 4.29415 14.6675Z" fill="currentColor"></path><path d="M8.06541 22.2201L10.1378 15.2571C10.1759 15.1288 10.0799 15 9.94607 15H4.5H4.41655C4.17104 15 4.1415 14.6431 4.38367 14.6027L5 14.5L19 11H19.0266C19.2034 11 19.2933 11.2126 19.17 11.3394L8.4005 22.4166C8.25477 22.5665 8.00578 22.4205 8.06541 22.2201Z" fill="currentColor"></path></svg>
    const svg3 = <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.2428 4.73756C15.2428 6.95855 17.0459 8.75902 19.2702 8.75902C19.5151 8.75782 19.7594 8.73431 20 8.68878V16.6615C20 20.0156 18.0215 22 14.6624 22H7.34636C3.97851 22 2 20.0156 2 16.6615V9.3561C2 6.00195 3.97851 4 7.34636 4H15.3131C15.2659 4.243 15.2423 4.49001 15.2428 4.73756ZM13.15 14.8966L16.0078 11.2088V11.1912C16.2525 10.8625 16.1901 10.3989 15.8671 10.1463C15.7108 10.0257 15.5122 9.97345 15.3167 10.0016C15.1211 10.0297 14.9453 10.1358 14.8295 10.2956L12.4201 13.3951L9.6766 11.2351C9.51997 11.1131 9.32071 11.0592 9.12381 11.0856C8.92691 11.1121 8.74898 11.2166 8.63019 11.3756L5.67562 15.1863C5.57177 15.3158 5.51586 15.4771 5.51734 15.6429C5.5002 15.9781 5.71187 16.2826 6.03238 16.3838C6.35288 16.485 6.70138 16.3573 6.88031 16.0732L9.35125 12.8771L12.0948 15.0283C12.2508 15.1541 12.4514 15.2111 12.6504 15.1863C12.8494 15.1615 13.0297 15.0569 13.15 14.8966Z" fill="currentColor"></path><circle opacity="0.4" cx="19.5" cy="4.5" r="2.5" fill="currentColor"></circle></svg>

    const svg4 = <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32"><path opacity="0.4" d="M21.25 13.4764C20.429 13.4764 19.761 12.8145 19.761 12.001C19.761 11.1865 20.429 10.5246 21.25 10.5246C21.449 10.5246 21.64 10.4463 21.78 10.3076C21.921 10.1679 22 9.97864 22 9.78146L21.999 7.10415C21.999 4.84102 20.14 3 17.856 3H6.144C3.86 3 2.001 4.84102 2.001 7.10415L2 9.86766C2 10.0648 2.079 10.2541 2.22 10.3938C2.36 10.5325 2.551 10.6108 2.75 10.6108C3.599 10.6108 4.239 11.2083 4.239 12.001C4.239 12.8145 3.571 13.4764 2.75 13.4764C2.336 13.4764 2 13.8093 2 14.2195V16.8949C2 19.158 3.858 21 6.143 21H17.857C20.142 21 22 19.158 22 16.8949V14.2195C22 13.8093 21.664 13.4764 21.25 13.4764Z" fill="currentColor"></path><path d="M15.4303 11.5887L14.2513 12.7367L14.5303 14.3597C14.5783 14.6407 14.4653 14.9177 14.2343 15.0837C14.0053 15.2517 13.7063 15.2727 13.4543 15.1387L11.9993 14.3737L10.5413 15.1397C10.4333 15.1967 10.3153 15.2267 10.1983 15.2267C10.0453 15.2267 9.89434 15.1787 9.76434 15.0847C9.53434 14.9177 9.42134 14.6407 9.46934 14.3597L9.74734 12.7367L8.56834 11.5887C8.36434 11.3907 8.29334 11.0997 8.38134 10.8287C8.47034 10.5587 8.70034 10.3667 8.98134 10.3267L10.6073 10.0897L11.3363 8.61268C11.4633 8.35868 11.7173 8.20068 11.9993 8.20068H12.0013C12.2843 8.20168 12.5383 8.35968 12.6633 8.61368L13.3923 10.0897L15.0213 10.3277C15.2993 10.3667 15.5293 10.5587 15.6173 10.8287C15.7063 11.0997 15.6353 11.3907 15.4303 11.5887Z" fill="currentColor"></path></svg>
  
  const chart2 = {
    options: {

        colors: chartColors,
        chart: {

            toolbar: {
                show: true,
            },
        },
        forecastDataPoints: {
            count: 0,
        },
        stroke: {
            width: 0,
        },
        grid: {
            show: true,
            strokeDashArray: 7,
        },
        markers: {
            size: 6,
            colors: "#FFFFFF",
            strokeColors: colors,
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 0,
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    },
    series:selectedChartData,
}
   
    return (
        <Fragment>
           
           
      
           
  
          
              

                <Col lg="8" md="12">
                    <Card className="card-block card-stretch card-height">
                        <Card.Header>
                            <div className=" d-flex justify-content-between  flex-wrap">
                                <h4 className="card-title">{chartTitle}</h4>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle" size="sm" id="dropdownMenuButton1">
                                        This year
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">Year</Dropdown.Item>
                                        <Dropdown.Item href="#">Month</Dropdown.Item>
                                        <Dropdown.Item href="#">Week</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Card.Header>
                        <Card.Body>
                        <Chart
          options={chartOptions}
          series={selectedChartData}
          type="bar"
          height="350"
        />                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="4">
   <Row>
<div  className="col-6" style={{ cursor: 'pointer',  fontSize:"17px"}} onClick={()=>handleSelectingCard(1)}>
    <AdCard1 svg={svg1} dollar="€" start={0} end={props.isDriver ? 525 : 14532} duration={5} TotalEarning="Total charging cost" selected={selectedCard===1? true : false} />
</div>
<div  className="col-6"  style={{ cursor: 'pointer', fontSize:"17px" }} onClick={()=>handleSelectingCard(2)}>
    <AdCard1 svg={svg2} dollar="kWh" start={0} end={props.isDriver ? 4123 : 123342} duration={5} TotalEarning="Total energy consumption" selected={selectedCard===2? true : false} />
</div>
<div  className="col-6" style={{ cursor: 'pointer',  fontSize:"17px"}} onClick={()=>handleSelectingCard(3)}>
    <AdCard1 svg={svg3} dollar="Kg" start={0} end={props.isDriver ? 75 : 1295} duration={5} separator="," TotalEarning="Total CO2 emission" selected={selectedCard===3? true : false}/>
</div>
<div  className="col-6"  style={{ cursor: 'pointer', fontSize:"18px" }} onClick={()=>handleSelectingCard(4)}>
    <AdCard1 svg={svg4} dollar="Kg" start={0} end={props.isDriver ? 23 : 530} duration={6} separator="," TotalEarning="Total CO2 avoided" selected={selectedCard===4? true : false}/>
</div>
 </Row>
                </Col>

           
        </Fragment>
    )
}
)

ChartsAndCards.displayName = "ChartsAndCards"
export default ChartsAndCards
