import React,{useEffect,useState} from 'react'


// default layout
import Default from '../layouts/dashboard/default';

// Dashboard
import Index from '../views/dashboard/index'
import { useSelector,useDispatch } from 'react-redux';

//EVs
import Vehicles from '../components/EVs/vehiclesList';
import SingleVehicle from "../components/EVs/singleVehicle";
// Drivers
import Drivers from '../components/drivers/driversList';
import SingleDriver from "../components/drivers/singleDriver";
import AddDrivers from '../components/addDrivers';
import SettingsSection from '../components/settingsComponent';
import EnergyInfos from '../components/EnergyInfosComponent';


import CarbonfootprintByMake  from '../components/reports/carbonfootprint';
import { Navigate, Route, Routes } from 'react-router-dom';

// const isAuthenticated = useSelector((state)=>state.account)

const isAuthenticated = localStorage.getItem('auth-token')? true : false;
export const DefaultRouter = [
    {
        path: "/",
        element:
         <Default />,
        children:[
            {
                path: "dashboard",
                element:  <Routes>
                      <Route path="/" element={isAuthenticated ? <Index /> : <Navigate to="/" />} />
                    </Routes>
                // element: <Index />
                },
                

                {
                    path: "/",
                    element:  <Routes>
                          <Route path="/" element={!isAuthenticated ? <Default /> : <Navigate to="/" />} />
                        </Routes>
                    // element: <Index />
                    },
            {
                path: 'vehicles',
                element: <Routes>
                  <Route path="/" element={isAuthenticated ? <Vehicles /> : <Navigate to="/" />} />
                </Routes>,
              },
            {
                path : "vehicle/:id",
                element: <Routes>
                  <Route path="/" element={isAuthenticated ? <SingleVehicle /> : <Navigate to="/" />} />
                </Routes>,
              
            },
            {
                path : "drivers",
                element: <Routes>
                <Route path="/" element={isAuthenticated ? <Drivers /> : <Navigate to="/" />} />
              </Routes>,
            },
            {
                path : "driver/:id",
                element: <Routes>
                <Route path="/" element={isAuthenticated ? <SingleDriver/> : <Navigate to="/" />} />
              </Routes>,
            },
            {
                path : "add-driver",
                element: <Routes>
                <Route path="/" element={isAuthenticated ? <AddDrivers/> : <Navigate to="/" />} />
              </Routes>,
            },
            {
                path : "profile",
                element: <Routes>
                <Route path="/" element={isAuthenticated ? <SettingsSection/> : <Navigate to="/" />} />
              </Routes>,
            },
            {
                path : "energy-infos",
                element: <Routes>
                <Route path="/" element={isAuthenticated ? <EnergyInfos/> : <Navigate to="/" />} />
              </Routes>,
            },
            {
              path : "reporting",
              element: <Routes>
                <Route path="/" element={isAuthenticated ? <CarbonfootprintByMake/> : <Navigate to="/" />} />
              </Routes>,
            },
                     

        ]
    },
]


