import { useState, useEffect, memo, Fragment } from 'react'
import { Row, Col, Dropdown, Button, Form, Table, Card, Tab, Nav,Navbar,Container,NavDropdown, Offcanvas, } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import MultiStepModal from "../multiStepsModal";
import { signin } from '../../store/Account/actions'
// import {getCarInfos} from "../../store/Car/actions"

import { useDispatch, useSelector } from 'react-redux'
import {updateVehicle} from "../../store/Car/actions";
import {updateDriver} from "../../store/Account/actions";



import * as SettingSelector from '../../store/setting/selectors'
import {setSelectedCarProfile,setAllCars} from "../../store/Car/carSlice"
import {editDriver} from "../../store/Account/accountSlice"




const Vehicles = memo((props) => {
    useSelector(SettingSelector.theme_color)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const themeSchemeDirection = useSelector(SettingSelector.theme_scheme_direction);

    const getVariableColor = () => {
        let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
        if (prefix) {
            prefix = prefix.trim()
        }
        const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
        const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
        const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
        const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
        return {
            primary: color1.trim(),
            info: color2.trim(),
            warning: color4.trim(),
            primary_light: color3.trim(),
        };
    }
    const variableColors = getVariableColor();
    const {allCars} = useSelector((state)=> state.car)
    const {allDrivers} = useSelector((state)=> state.account)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [show, setShow] = useState(false);
    const [filtredDrivers,setFiltredDrivers] = useState(allDrivers.filter((driver)=>driver.currentVehicule ===null))
    const [selectedCar,setSelectedCar] = useState();
    const [filteredResults, setFilteredResults] = useState([]);

    const [searchInput, setSearchInput] = useState('');

    const colors = [variableColors.primary, variableColors.info];
    useEffect(() => {
        return () => colors
    })
    useEffect(() => {
        setFilteredResults(allCars)

    },[allCars])
    useEffect(() => {
        setFiltredDrivers(allDrivers.filter((driver)=>driver.currentVehicule ===null))

    },[allDrivers])
const selectVehicle = (vehicle)=>{
  
    dispatch(setSelectedCarProfile(vehicle))
    navigate(`/vehicle/${vehicle.id}`)
   
};

const handlePercentage = (percentage) => {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  
    const formattedPercentage = formatter.format(percentage);
  
    return formattedPercentage;
  };

const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== '') {
      const filteredData = allCars.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(allCars);
    }
  };
  const assignDriverToCar = (driver) => {
    const updatedVehicle = {...selectedCar};
    updatedVehicle.currentUser = driver;
    setSelectedCar(updatedVehicle);
    const CarIndex = allCars.findIndex(car=>car.id === selectedCar.id)
    const newAllCarsList = [...allCars];
    newAllCarsList[CarIndex] = updatedVehicle;
    dispatch(setAllCars(newAllCarsList));
    setShow(false);
    dispatch(editDriver({driver,car:updatedVehicle}));
    let updatedVehicleId = updatedVehicle.id;
    dispatch(updateVehicle({
        onSuccess: (result) => {
            const newDriver = {
                ...driver,
                currentVehicule: updatedVehicle,
              };
              const driverid = driver.id;
            dispatch(
                updateDriver({
                  onSuccess: (result) => {
                    console.log('result: ' + result);
                    // resolve(result);
                  },
                  driverid,newDriver,
                  onFailure: (e) => {
                    console.log(e,"error")
                    // reject(e);
                  },
                })
              );
        },updatedVehicleId,updatedVehicle,
        onFailure: (e) => {
            console.log(e,"error")
            // reject(e);
          },
    }))
   

  };
  const handleAssignButton =  (car) => {
    setSelectedCar(car);
    setShow(true);
};
const handleFiltring =(filterType) => {
    if (filterType === 'hasDriver') {
        setFilteredResults(allCars.filter((car)=>car.currentUser !==null))
    }
    if (filterType === 'noDriver') {
        setFilteredResults(allCars.filter((car)=>car.currentUser ===null))
    }
    if (filterType === 'connected') {
        setFilteredResults(allCars.filter((car)=>car.status ==="Connected"))
    }
    if (filterType === 'disconnected') {
        setFilteredResults(allCars.filter((car)=>car.status ==="Disconnected"))
    }
    else if (filterType === 'allEVs') {
        setFilteredResults(allCars)
    }
};
    return (
        <Fragment>
                             <h3>EVs</h3>

         <Navbar bg="white" expand="lg" className="navbar-light">
    <Container fluid>
        <Navbar.Collapse className="collapse" id="navbarSupportedContent">
            <Nav className=" me-auto mb-2 mb-lg-0">
                <NavDropdown title="Filter" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#" onClick={()=>handleFiltring("allEVs")}>All EVs</NavDropdown.Item>

                    <NavDropdown.Item href="#" onClick={()=>handleFiltring("hasDriver")}>Assigned</NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={()=>handleFiltring("noDriver")}>Not assigned</NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={()=>handleFiltring("connected")}>Connected</NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={()=>handleFiltring("disconnected")}>Not connected</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <div className="d-flex">
                <input className="form-control me-5" type="search" placeholder="Search" aria-label="Search" onChange={(e) => searchItems(e.target.value)} />
                <Button className="btn btn-outline-dark bg-primary" style={{ width: "80%", backgroundColor: "#3B44F6" }} onClick={() => setIsModalVisible(true)}>Add Ev</Button>
                {isModalVisible && <MultiStepModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />}
            </div>
        </Navbar.Collapse>
    </Container>
</Navbar>

              

                         <Col lg="60" md="12">
                    <Card className="card-block">
                        <Tab.Container defaultActiveKey="first">
                        
                            <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                                <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                                    <div className="table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <tbody>
                                            <tr>
                                                <td>
                                                        <div className="d-flex align-items-center" >
                                                            <h6 className="mb-0">Vehicles</h6>
                                                        </div>
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0">Plate Number</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0">Status</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0">Soc</h6>
                                                        </div>
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0">Range</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0">Assigned</h6>
                                                        </div>
                                                    </td>

                                                    
                                                </tr>
                                                <div className="tab-bottom-bordered"></div>
                                                {filteredResults?.map((Vehicle)=>(
                                                      <tr>
                                                      <td>
                                                              <div className="d-flex align-items-center" style={{cursor:"pointer"}} onClick={()=>selectVehicle(Vehicle)}>
                                                         
                                                             
                                                                  <h6 className="mb-0" >{Vehicle.make+" " +Vehicle.model}</h6>
                                                                
                                                              </div>
                                                          </td>
                                                          
                                                          <td className="text-dark">
                                                              {Vehicle.plateNumber}
                                                          </td>
                                                          <td className="text-primary">
                                                          {
Vehicle.status === 'Connected' ? (
<div style={{ display: "flex", alignItems: "center" }}>
<div style={{ marginLeft: "0px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: variableColors.primary }}></div>
<span style={{ marginLeft: "5px", color: variableColors.primary }}>{Vehicle.status}</span>
</div>
) : (
<div style={{ display: "flex", alignItems: "center" }}>
<div style={{ marginLeft: "0px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor:"red" }}></div>
<span style={{ marginLeft: "5px", color: "red" }}>{Vehicle.status}</span>
</div>
)
}
                                                        
                                                          {/* <span className={`badge rounded-pill ${Vehicle.status === 'Connected' ? 'bg-success' : 'bg-danger'}`}>{Vehicle.status}</span> */}
                                                          </td>
                                                          <td className="">
                                                          <span className={`badge rounded-pill`} style={{backgroundColor:` ${
                                                                 Vehicle.soc >= 0.8 && Vehicle.chargingStatus!=="Charging" ? '#1cd90d'
                                                                : Vehicle.soc >= 0.4 && Vehicle.chargingStatus!=="Charging" ? '#6103f2'
                                                                : Vehicle.chargingStatus==="Charging" ? '#40454d'
                                                                :  '#ba4d42'
                                                                }`}}>{handlePercentage(Vehicle.soc)}</span>
      
                                                          </td>
                                                          
                                                          <td className="text-dark">{Vehicle.range}</td>
                                                          <div className="d-flex align-items-center">
                                                          {Vehicle.currentUser!== null  ? (
                                                         <td className="text-dark ">{Vehicle.currentUser.firstName+" "+Vehicle.currentUser.lastName}</td>
                                                          ) :(
                                                            <h6 style={{cursor:"pointer"}}>
                                                                                                                            <u className='text-primary' onClick={()=>handleAssignButton(Vehicle)}>Assign</u>


                                                            </h6>
                                                            )}
                                                        </div>
                                                          
                                                      </tr>
                                                ))}
 
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab.Pane>
                             
                               
                            </Tab.Content>
                        </Tab.Container>
                     
                    </Card>
                </Col>

                
                <Offcanvas show={show} onHide={() => setShow(false)} placement={`${themeSchemeDirection === "rtl" ? 'start' : 'end'}`} backdrop={false} scroll={true} className="live-customizer">
                <Offcanvas.Header closeButton>
               <h3 style={{marginLeft:"10px"}}>Unassigned Drivers</h3>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                     
                        <Col md={12}>
                            <div className="border border-2 rounded">
                            <Tab.Container defaultActiveKey="first">
                        
                        <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                            <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                                <div className="table-responsive">
                                    <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                        <tbody>
                                        <tr>
                                            <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Driver</h6>
                                                    </div>
                                                </td>
                                                
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Email</h6>
                                                    </div>
                                                </td>
                                                
                                                
                                              
                                                {/* <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Brand</h6>
                                                    </div>
                                                </td> */}

                                                
                                            </tr>
                                            <div className="tab-bottom-bordered"></div>
                                            {filtredDrivers.map((driver)=>(
                                             
  <tr >
    
  <td>
          <div className="d-flex align-items-center">
          {/* <img src={driver.profilPic} style={{ height: "30px", width: "50px",marginTop:"2px",cursor:"pointer" }} onClick={()=>assignDriverToCar(driver)}/> */}
          <Col>
          <td >
          <div className="d-flex align-items-center"  style={{ height: "30px",cursor:"pointer" }} onClick={()=>assignDriverToCar(driver)}>
          <h6 className="mb-0">{driver.firstName+" "+driver.lastName}</h6>
          </div>
      </td>
          {/* <span className={`badge rounded-pill ${
                       vehicle.soc >= 70 ? 'bg-success'
                      : vehicle.soc >= 40 ? 'bg-warning'
                      : 'bg-danger'
                      }`}>{vehicle.soc}%</span> */}
                      {/* <span className={`badge rounded-pill bg-success`}>30%</span> */}

          </Col>

          </div>
      </td>
      
      <td>
          <div className="d-flex align-items-center">
              <h6 className="mb-0">{driver.email}</h6>
          </div>
      </td>
      
      
    
      <td>
          <div className="d-flex align-items-center">
              {/* <h6 className="mb-0">{driver.position}</h6> */}
          </div>
      </td>

      
  </tr>
                                            ))}
                                          

                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                         
                           
                        </Tab.Content>
                    </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    )
}
)

Vehicles.displayName = "Vehicles"
export default Vehicles
