import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import { configureStore } from '@reduxjs/toolkit';
// import settingReducer from './setting/reducers';
 import settingReducer from './setting/reducers';
//  import carReducer from "./Car/reducers"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { http } from '../config/middelwares/http'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import accountSlice from './Account/accountSlice';
import carSlice from './Car/carSlice'
const persistConfig = {
  key: 'root',
  version: 1,
  storage: AsyncStorage,
  whitelist: ['setting','account','car'],
}

const getPersistedReducer = () => {
  return persistReducer(persistConfig, settingReducer);
};


export const store = configureStore({
  reducer: {

    account: accountSlice.reducer,
    car : carSlice.reducer,
    setting: getPersistedReducer()

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(thunk, http),
  devTools: process.env.NODE_ENV !== 'production'
});
