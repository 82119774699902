import { useState, useEffect, memo, Fragment } from 'react'


// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors'
import { useNavigate } from 'react-router-dom';
//Count-up






const DirectingToDashboard = memo(() => {
  const navigate = useNavigate();
    useEffect(() => {
        navigate('/dashboard')
    },[])

 




  

 
  
    return (
<>
</>
    )
}
)

DirectingToDashboard.displayName = "DirectingToDashboard"
export default DirectingToDashboard
