import { useEffect, memo, Fragment } from "react";
import { Link } from "react-router-dom";
import Scrollbar from "smooth-scrollbar";
import * as SettingSelector from "../../../../store/setting/selectors";
import { useSelector,useDispatch } from "react-redux";
import VerticalNav from "./vertical-nav";
// import Logo from "../../components/logo";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate,useHistory } from 'react-router-dom';
import { logout} from '../../../../store/Account/accountSlice';
import Logo from '../../../../assets/images/logo/logo@3x.png'


const Sidebar = memo((props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const sidebarColor = useSelector(SettingSelector.sidebar_color);
  const sidebarHide = useSelector(SettingSelector.sidebar_show);
  const sidebarType = useSelector(SettingSelector.sidebar_type);
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };
  const handleLogout = () => {
    navigate("/")
  };
  useEffect(() => {
    Scrollbar.init(document.querySelector("#my-scrollbar"));

    window.addEventListener("resize", () => {
      // ...existing resize logic...
    });
  });

  const logoutAccount = () =>{
    //  localStorage.removeItem('auth-token');
    dispatch(logout()) 
    console.log("here is logged out")

};

  return (
    <Fragment>
      <aside
        className={` ${sidebarColor} ${sidebarType.join(" ")} ${sidebarMenuStyle} ${
          sidebarHide.join(" ") ? "sidebar-none" : "sidebar"
        }   sidebar-base  `}
        data-sidebar="responsive"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <div className="sidebar-header d-flex align-items-center justify-content-start">
          <Link to="/dashboard" className="navbar-brand">
            {/* <Logo /> */}
            <Image width={"40px"} src={Logo}/>
            <h4 className="logo-title">{props.app_name}</h4>
          </Link>
          <div
            className="sidebar-toggle"
            data-toggle="sidebar"
            data-active="true"
            onClick={minisidebar}
          >
            <i className="icon">
              <svg
                width="20"
                className="icon-20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.25 12.2744L19.25 12.2744"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </i>
          </div>
        </div>
        <div
          className="pt-0 sidebar-body data-scrollbar"
          data-scroll="1"
          id="my-scrollbar"
          style={{ flex: 1, overflowY: "auto" }}
        >
          <div className="sidebar-list navbar-collapse" id="sidebar">
            <VerticalNav />
          </div>
        </div>
       
        <div className="nav-item " style={{marginBottom : "5px"}} >
                    <Link className="nav-link" aria-current="page" to="/" onClick={handleLogout}>
                    <OverlayTrigger placement="right" overlay={<Tooltip>Logout</Tooltip>}>
                            <i className="icon">
                            <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32"><path opacity="0.4" d="M7.29639 6.446C7.29639 3.995 9.35618 2 11.8878 2H16.9201C19.4456 2 21.5002 3.99 21.5002 6.436V17.552C21.5002 20.004 19.4414 22 16.9098 22H11.8775C9.35205 22 7.29639 20.009 7.29639 17.562V16.622V6.446Z" fill="currentColor"></path><path d="M16.0374 11.4538L13.0695 8.54482C12.7627 8.24482 12.2691 8.24482 11.9634 8.54682C11.6587 8.84882 11.6597 9.33582 11.9654 9.63582L13.5905 11.2288H3.2821C2.85042 11.2288 2.5 11.5738 2.5 11.9998C2.5 12.4248 2.85042 12.7688 3.2821 12.7688H13.5905L11.9654 14.3628C11.6597 14.6628 11.6587 15.1498 11.9634 15.4518C12.1168 15.6028 12.3168 15.6788 12.518 15.6788C12.717 15.6788 12.9171 15.6028 13.0695 15.4538L16.0374 12.5448C16.1847 12.3998 16.268 12.2038 16.268 11.9998C16.268 11.7948 16.1847 11.5988 16.0374 11.4538Z" fill="currentColor"></path></svg>
                            </i>
                        </OverlayTrigger>
                        <span className="item-name"   onClick={logoutAccount}>logout</span>
                    </Link>
                </div>
      </aside>
    </Fragment>
  );
});

Sidebar.displayName = "Sidebar";
export default Sidebar;
