import { useState, useEffect, memo, Fragment } from 'react'
import { Row, Col, Dropdown, Button, Form, Table, Card, Tab, Nav,ListGroup, ListGroupItem ,Image} from 'react-bootstrap'
import ChargingHistoryModal from '../chargingHistoryModal'
// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux'
import {setSelectedCarProfile,setAllCars} from "../../store/Car/carSlice"

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors'
import ChartsAndCards from '../Charts&Cards';
import AssignedDriverHistoryModal from '../assignedDriverHistoryModal';
import { useParams } from 'react-router-dom';
import {findVehicleById} from "../../store/Car/actions"


//Count-up






const SingleVehicle = memo((props) => {
  const dispatch = useDispatch();
  const { allCars } = useSelector((state) => state.car);

  const { id } = useParams();
    useSelector(SettingSelector.theme_color)
    useEffect(() => {
      // console.log(id,"id: " + allCars)
dispatch(findVehicleById({
  onSuccess: (result) => {
    console.log('result: ' + result);
    // resolve(result);
  },
  id,
  onFailure: (e) => {
    console.log(e,"error")
    // reject(e);
  }}));
      // dispatch(setSelectedCarProfile(allCars.filter(car => car.id === id)));
    }, []);
    const getVariableColor = () => {
        let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
        if (prefix) {
            prefix = prefix.trim()
        }
        const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
        const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
        const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
        const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
        return {
            primary: color1.trim(),
            info: color2.trim(),
            warning: color4.trim(),
            primary_light: color3.trim(),
        };
    }
    
    const variableColors = getVariableColor();
    const [checked, setChecked] = useState(true);
    const colors = [variableColors.primary, variableColors.info];
    const chartColors = [variableColors.info, variableColors.primary,"grey"];

    const [selectedperiod,setSelectedPeriod] = useState("Month")
    const [selectedCard,setSelectedCard] = useState(1);
    const { selectedCarProfile} = useSelector((state) => state.car);
    const [isModalVisible,setIsModalVisible] = useState(false);
    const [isDriversModalVisible,setIsDriversModalVisible] = useState(false);
    useEffect(() => {
        return () => colors
    })

    const handleSelectingCard = (number) =>{
        setSelectedCard(number)
    };




  
    const chargingHistorytable = [
      {
          id: 1,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'John Doe',
          description: 'April 15th - 17:30 - 6:15',
          status: 'EV charged to 60%',
          backgroundColor: 'white'
        },
        {
          id: 2,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'Jane Smith',
          description: 'April 18th - 21:45 - 7:45',
          status: 'EV charged to 95%',
          backgroundColor: 'white'
        },
        {
          id: 3,
          icon:<svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="32" height="32" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.7044 3.51898C10.034 3.51898 9.46373 3.9848 9.30365 4.61265H14.6863C14.5263 3.9848 13.956 3.51898 13.2856 3.51898H10.7044ZM16.2071 4.61264H18.1881C20.2891 4.61264 22 6.34428 22 8.47085C22 8.47085 21.94 9.3711 21.92 10.6248C21.918 10.724 21.8699 10.8212 21.7909 10.88C21.3097 11.2354 20.8694 11.5291 20.8294 11.5493C19.1686 12.6632 17.2386 13.447 15.1826 13.8369C15.0485 13.8632 14.9165 13.7934 14.8484 13.6739C14.2721 12.6754 13.1956 12.0253 11.995 12.0253C10.8024 12.0253 9.71586 12.6683 9.12256 13.6678C9.05353 13.7853 8.92346 13.8531 8.7904 13.8278C6.75138 13.4369 4.82141 12.6541 3.17059 11.5594L2.21011 10.8911C2.13007 10.8405 2.08004 10.7493 2.08004 10.6481C2.05003 10.1316 2 8.47085 2 8.47085C2 6.34428 3.71086 4.61264 5.81191 4.61264H7.78289C7.97299 3.1443 9.2036 2 10.7044 2H13.2856C14.7864 2 16.017 3.1443 16.2071 4.61264ZM21.6598 12.8152L21.6198 12.8355C19.5988 14.1924 17.1676 15.0937 14.6163 15.4684C14.2561 15.519 13.8959 15.2861 13.7959 14.9216C13.5758 14.0912 12.8654 13.5443 12.015 13.5443H12.005H11.985C11.1346 13.5443 10.4242 14.0912 10.2041 14.9216C10.1041 15.2861 9.74387 15.519 9.38369 15.4684C6.83242 15.0937 4.4012 14.1924 2.38019 12.8355C2.37019 12.8254 2.27014 12.7646 2.1901 12.8152C2.10005 12.8659 2.10005 12.9874 2.10005 12.9874L2.17009 18.1519C2.17009 20.2785 3.87094 22 5.97199 22H18.018C20.1191 22 21.8199 20.2785 21.8199 18.1519L21.9 12.9874C21.9 12.9874 21.9 12.8659 21.8099 12.8152C21.7599 12.7849 21.6999 12.795 21.6598 12.8152ZM12.7454 17.0583C12.7454 17.4836 12.4152 17.8177 11.995 17.8177C11.5848 17.8177 11.2446 17.4836 11.2446 17.0583V15.7519C11.2446 15.3367 11.5848 14.9924 11.995 14.9924C12.4152 14.9924 12.7454 15.3367 12.7454 15.7519V17.0583Z" fill="#1cd90d"></path></svg>
          ,
          name: 'Michael Johnson',
      description: 'April 20th - 19:00 - 6:30',
      status: 'EV charged to 70%',
          backgroundColor: 'white'
        },
        {
          id: 4,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'Emily Davis',
          description: 'April 22nd - 16:15 - 5:45',
          status: 'EV charged to 80%',
          backgroundColor: 'white'
        },
        {
          id: 5,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'Daniel Thompson',
          description: 'April 24th - 23:00 - 6:30',
          status: 'EV charged to 90%',
          backgroundColor: 'white'
        },
        {
          id: 6,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'Olivia Brown',
          description: 'April 26th - 18:45 - 6:00',
          status: 'EV charged to 75%',
          backgroundColor: 'white'
        },
        {
          id: 7,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'Aiden Taylor',
          description: 'April 28th - 20:20 - 7:00',
          status: 'EV charged to 85%',
          backgroundColor: 'white'
        },
        {
          id: 8,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'Sophia Martin',
      description: 'April 30th - 22:10 - 5:30',
      status: 'EV charged to 70%',
          backgroundColor: 'white'
        },
        {
          id: 9,
          icon: <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z" fill="#1cd90d"></path></svg>,
          name: 'Matthew Clark',
      description: 'May 2nd - 19:40 - 6:15',
      status: 'EV charged to 80%',
          backgroundColor: 'white'
        },
  
     ]
    const handlePercentage = (percentage) => {
        const formatter = new Intl.NumberFormat(undefined, {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
      
        const formattedPercentage = formatter.format(percentage);
      
        return formattedPercentage;
      };
    return (
        <Fragment>
           <h4>EVs &gt; <u>EV profile</u></h4>
            <Row>
            <Col md="5" style={{width : "40%"}}>
                
            <Card className="mb-0" style={{ height: "92%" }}>

<div style={{ display: "flex", alignItems: "center" }}>
  <div style={{ marginLeft: "10px", width: "10px", height: "10px", borderRadius: "50%", backgroundColor: variableColors.primary }}></div>
  <span style={{ marginLeft: "5px", color: "green" }}>Active Driver</span>
</div>
<h3 className="card-title" style={{ marginLeft: "5%", marginTop: "5px" }}>{selectedCarProfile?.make+" "+ selectedCarProfile?.model}</h3>
<Row className="g-0">
  <Col md="4">
    <img src={selectedCarProfile.imgUrl} style={{ height: "60%", width: "100%", marginTop: "20%" }} />
  </Col>
  <Col md="8">
    <Card.Body style={{ marginTop: "1%"}}>
      <div style={{ display: "flex", marginLeft: "15%", fontSize: "13px" }}>
        {/* <p className="text-dark">Battery level</p> */}
        {/* <p style={{ marginLeft: "10px", fontWeight: "bold" }} className="text-dark">{selectedCarProfile.soc ? selectedCarProfile.soc : <>60</>}%</p> */}
      </div>
      <div style={{ display: "flex", marginLeft: "15%", fontSize: "13px" }}>
        <p className="text-dark">Battery Capacity</p>
        <p style={{ marginLeft: "10px", fontWeight: "bold" }} className="text-dark">60.0 KWH</p>
      </div>
      <div style={{ display: "flex", marginLeft: "15%", fontSize: "13px" }}>
        <p className="text-dark">Odometer</p>
        <p style={{ marginLeft: "10px", fontWeight: "bold" }} className="text-dark">15000 Km</p>
      </div>
      <div style={{ display: "flex", marginLeft: "15%", fontSize: "13px" }}>
        <p className="text-dark">SOC</p>
        <p style={{ marginLeft: "10px" }} className="text-dark">
        <span className={`badge rounded-pill`} style={{backgroundColor:` ${
                                                                 selectedCarProfile.soc >= 0.8 && selectedCarProfile.chargingStatus!=="Charging" ? '#1cd90d'
                                                                : selectedCarProfile.soc >= 0.5 && selectedCarProfile.chargingStatus!=="Charging" ? '#6103f2'
                                                                : selectedCarProfile.chargingStatus==="Charging" ? '#40454d'
                                                                :  '#ba4d42'
                                                                }`}}>{handlePercentage(selectedCarProfile.soc)}</span>       </p>
      </div>
   
    </Card.Body>
    <div style={{ display: "flex", width: "150%", marginTop: "10px",marginLeft:"10%" }}>
        <Button variant="light" style={{ marginRight: "10px" }}>
     
          Disconnect EV
        </Button>
        <Button variant="light">
     
          Unassign
        </Button>
      </div>    
  </Col>

</Row>
<br />
<Row></Row>
<br />
</Card>
        </Col>
                {isModalVisible&& <ChargingHistoryModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} chargingHistorytable= {chargingHistorytable} />}
        <Col md="15" style={{ height: "309px", marginBottom: "10px", width: "30%", marginTop: "-2.2%" }}>
      <h5>Charging history</h5>
      <Card className="card-block" style={{height: "89%", overflow: "hidden" }}>
        <Tab.Container defaultActiveKey="first">
          <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
            <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                {chargingHistorytable.length > 3 &&(
            <h6 style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={() => setIsModalVisible(true)}>see more</h6>
                )}
              {chargingHistorytable.slice(0, 3).map((item) => (
                <div key={item.id} className="table-responsive" style={{ backgroundColor: item.backgroundColor }}>
                  <table id="basic-table" className="table mb-0 table-striped" role="grid">
                    <tbody>
                      <tr>
                        <td>
                          <div className="bg-soft-primary avatar-60 rounded">
                            {item.icon}
                          </div>
                        </td>
                        <td className="text-primary">
                          <div className="d-flex justify-content-between">
                            <h6>{item.name}</h6>
                          </div>
                          <div className=" shadow-none w-100" style={{ height: "20px" }}>
                            <h6 className="text-body">{item.description}</h6>
                          </div>
                          <div className="shadow-none w-100" style={{ height: "20px" }}>
                            <h6 className="">{item.status}</h6>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </Col>
    {isDriversModalVisible && <AssignedDriverHistoryModal isModalVisible={isDriversModalVisible} setIsModalVisible={setIsDriversModalVisible} AssignedDriverHistoryTable = { [...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers]}/>}
                <Col  md="15"  style={{ height: "309px", overflow: "hidden", marginBottom: "10px",width: "30%",marginTop:"-2.4%" }}>
                    
                    <h5>Assigned driver's history</h5>
                    <Card className="card-block" style={{height: "89%", overflow: "hidden" }}>
                        <Tab.Container defaultActiveKey="first">
                       
                            <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                                <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                               {
                                [...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers].length > 3 &&(
                                    <h6 style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={() => setIsDriversModalVisible(true)}>see more</h6>

                                )
                               }
             
                                    <div className="table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <tbody>
                                            {
                                    [...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers,...selectedCarProfile?.historicalUsers].map((item,index) =>
                                        {
                                            return (
                                                <div key={index} className="table-responsive" >
                                                  <table id="basic-table" className="table mb-0 table-striped" role="grid" style={{height:"94px" }}>
                                                    <tbody>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <Image className=" img-fluid w-35 me-3" src={item.profilPic} alt="" style={{borderRadius : "50px"}}/>
                                                            <div className="media-support-info">
                                                            <div className="d-flex justify-content-between align-items-center" style={{width:"80%"}}>

                                                                <h6 className="mb-0">{item.firstName+" "+ item.lastName}</h6>
                                                                {index === 0 && <span className="badge rounded-pill bg-primary">current</span>}
                                                                </div>
                                                                <div className=" w-100" style={{ height: "20px" }}>
                                                <h6 className="text-body">March 13th - March 29th</h6>
                                            </div>                                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                  
                                                   
                                                  

                                                </tr>
                                                </tbody>
                                                </table>
                                                </div>
                                            )       
                                        }
                                    )
                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab.Pane>
                           
                               
                            </Tab.Content>
                        </Tab.Container>
                      
                    </Card>
                </Col>
                <ChartsAndCards/>



            </Row>
        </Fragment>
    )
}
)

SingleVehicle.displayName = "SingleVehicle"
export default SingleVehicle
