import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';

//router
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import Index from './views/index'
import Dashboard from './views/dashboard/index';

//store

import { Provider } from 'react-redux';
//reducer
import { store } from './store'
import { IndexRouters } from './router';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },  
  ...IndexRouters,
],{ basename: process.env.PUBLIC_URL })
ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </Provider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
