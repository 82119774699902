import { useState, useEffect, memo, Fragment } from 'react'
import { Row, Col, Dropdown, Button, Form, Table, Card, Tab, Offcanvas,Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import {fetchAllDrivers} from '../../store/Account/actions';
import {fetchAllVehicles} from '../../store/Car/actions'


//apexcharts
import Chart from "react-apexcharts";


// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux'

// Import selectors & action from setting store
import * as SettingSelector from '../../store/setting/selectors'

//Count-up
import ChartsAndCards from '../../components/Charts&Cards';
import MultiStepModal from '../../components/multiStepsModal';
// import car from "../../assets/images/cars/Tesla-Model-3.png"
import car from "../../assets/images/cars/Tesla-Model-3.png"
import {setSelectedCarProfile,setAllCars} from "../../store/Car/carSlice"






const Index = memo((props) => {
    useSelector(SettingSelector.theme_color)
    const themeSchemeDirection = useSelector(SettingSelector.theme_scheme_direction)
    const navigate = useNavigate();
    const { allCars } = useSelector((state) => state.car);
    const { allDrivers } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const [evsStatus,setEVsStatus] = useState()
    const getVariableColor = () => {
        let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
        if (prefix) {
            prefix = prefix.trim()
        }
        const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
        const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
        const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
        const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
        return {
            primary: color1.trim(),
            info: color2.trim(),
            warning: color4.trim(),
            primary_light: color3.trim(),
        };
    }
    const variableColors = getVariableColor();
    const [show, setShow] = useState(false);
    const [isModalVisible,setIsModalVisible] = useState(false);
    const colors = [variableColors.primary, "#6103f2","#ba4d42","#40454d"];
    const [filtredEVs,setFiltredEVs] = useState([])
    useEffect(() => {
        
        return () => colors
    })
    useEffect(() => {
        const onClick = (e) => {
            if (show) {
                if (e.target.closest('.live-customizer') == null && e.target.closest('#settingbutton') == null) {
                    setShow(false)
                }
            }
        };
        document.body.addEventListener("click", onClick);

        return () => {
            document.body.removeEventListener("click", onClick);
        };
    })
    useEffect(() => {
        dispatch(
            fetchAllDrivers({
                onSuccess: (result)=>{
                    console.log(result)
                } ,
                 onFailure :(error) => {
                    console.log(error)
                 }
            })
          )
        
    },[allDrivers])
    useEffect(() => {
        dispatch(
            fetchAllVehicles({
                onSuccess: (result)=>{
                    console.log(result)
                } ,
                 onFailure :(error) => {
                    console.log(error)
                 }
            })
          )
       
    },[allCars])

    const filterEVs = (status) => {
        if (status==="charged") {
            setFiltredEVs(allCars.filter((car)=>Number(car.soc) >0.8 && car.chargingStatus !== "Charging")) 
            setEVsStatus("charged")
        }
        else if (status==="recommended") {
            setFiltredEVs(allCars.filter((car)=>Number(car.soc) <0.8 && Number(car.soc) >0.4 && car.chargingStatus !== "Charging")) 
            setEVsStatus("recommended")

        }
        else if (status==="need") {
            setFiltredEVs(allCars.filter((car)=>Number(car.soc) <0.4 && Number(car.soc) >0.0 && car.chargingStatus !== "Charging")) 
            setEVsStatus("need")

        }
        else{
            setFiltredEVs(allCars.filter((car)=>car.chargingStatus === "Charging")) 
            setEVsStatus("charging")


        }
    }
    const chart4 = {
        options : {
            colors: colors,
            plotOptions: {
                radialBar: {
                    inverseOrder: false,
                    endAngle: 360,
                    hollow: {
                        margin: 5,
                        size: '50%',
                        background: 'transparent',
                        imageWidth: 150,
                        imageHeight: 150,
                        imageClipped: true,
                        position: 'front',
                        dropShadow: {
                          enabled: false,
                          blur: 3,
                          opacity: 0.5
                        }
                    },
                    track: {
                        show: true,
                        background: '#f2f2f2',
                        strokeWidth: '70%',
                        opacity: 1,
                        margin: 6,
                        dropShadow: {
                            enabled: false,
                            blur: 3,
                            opacity: 0.5
                        }
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '16px',
                            fontWeight: 600,
                            offsetY: -10
                            },
                            value: {
                            show: true,
                            fontSize: '14px',
                            fontWeight: 400,
                            offsetY: 16,
                            formatter: function (val) {
                                return val + '%'
                            }
                        },
                    }
                }
            },
            labels: ['Fully Charged EVs', 'Charging recommended',"Need of charging", "Charging"],

        },
        series: [44, 85,22,15],
      
    }
   
   
  




const selectEvProfile = (vehicle)=>{
    dispatch(setSelectedCarProfile(vehicle))
    navigate(`/vehicle`)
   
};
const handlePercentage = (percentage) => {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  
    const formattedPercentage = formatter.format(percentage);
  
    return formattedPercentage;
  };


    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                <div className="d-flex flex-column">
                    <h3>Dashboard</h3>
                </div>
             
            </div>
            <Row>
         
            <Col lg="2" md="6">
  <Card className="card-block card-stretch card-height">
    <Card.Body className="d-flex flex-column align-items-center justify-content-around">
      <div>
        <h2 style={{ fontSize: "50px" }} className="text-primary">{allCars.filter(car=>car.status ==="Connected").length}</h2>
      </div>
      <div className="mb-3">
        <h2 className="counter" style={{ fontSize: "17px" }}>Connected EVs</h2>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Button variant="light" className="mb-3 d-flex w-100" onClick={() => setIsModalVisible(true)}>
          <div className="d-flex"></div>
        
          Add EV
        </Button>
      </div>
    </Card.Body>
  </Card>
</Col>

<Col lg="2" md="6">
  <Card className="card-block card-stretch card-height">
    <Card.Body className="d-flex flex-column align-items-center justify-content-around">
    <div>
        <h2 style={{ fontSize: "50px",color:"#ba4d42" }}>{allCars.filter(car=>car.currentUser ===null).length}</h2>
      </div>
      <div className="mb-3">
        <h2 className="counter" style={{ fontSize: "17px" }}>Unassigned EVs</h2>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Button variant="light" className="mb-3 d-flex w-100" onClick={() => navigate('/drivers')}>
        
          Assign EV
        </Button>
      </div>
    </Card.Body>
  </Card>
</Col>

<Col lg="2" md="6">
  <Card className="card-block card-stretch card-height">
    <Card.Body className="d-flex flex-column align-items-center justify-content-around">
    <div>
        <h2 style={{ fontSize: "50px",color:"#40454d" }} >{allDrivers.length}</h2>
      </div>
      <div className="mb-3">
        <h2 className="counter" style={{ fontSize: "17px" }}>Drivers</h2>
      </div>
      <div className="d-flex flex-column align-items-center">
        <Button variant="light" className="mb-3 d-flex w-100" onClick={() => navigate('/add-driver')}>
        
          Add Driver
        </Button>
      </div>
    </Card.Body>
  </Card>
</Col>

                
                {isModalVisible && <MultiStepModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />}

               
                  
                   
              
                <Col lg="6" md="12">
                    <Card>
                        <Card.Body>
                        <h4>EVs SOC</h4>

                        <div className="d-flex align-items-center justify-content-between  flex-wrap" style={{ height: '100%',width:"100%" }}>
                            <Chart  options={chart4.options} series={chart4.series} type="radialBar"  className="col-md-7 col-lg-7"  height="260"  />
                            <div className="d-grid gap col-md-5 col-lg-5">
    <div className="d-flex align-items-start" onClick={(e) => { e.stopPropagation(); filterEVs("charged");setShow(true) }} style={{ cursor: 'pointer', }}>
        <svg className="mt-2 text-primary" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="currentColor">
            <g><circle cx="12" cy="12" r="8" fill="currentColor"></circle></g>
        </svg>
        <div className="ms-2" >
            <span className="mb-3">Fully Charged</span>
            
        </div>
    </div>
    <div className="d-flex align-items-start" onClick={(e) => { e.stopPropagation(); filterEVs("recommended");setShow(true) }} style={{ cursor: 'pointer', }}>
        <svg className="mt-2 text-info" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#6103f2">
            <g><circle cx="12" cy="12" r="8" fill="#6103f2"></circle></g>
        </svg>
        <div className="ms-2">
            <span className="mb-3">Sufficient charge</span>
            
        </div>
    </div>
    <div className="d-flex align-items-start" onClick={(e) => { e.stopPropagation(); filterEVs("need");setShow(true) }} style={{ cursor: 'pointer', }}>
        <svg className="mt-2 text-danger" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#ba4d42">
            <g><circle cx="12" cy="12" r="8" fill="#ba4d42"></circle></g>
        </svg>
        <div className="ms-2">
            <span className="mb-3">Charging needed</span>
            
        </div>
    </div>
    <div className="d-flex align-items-start" onClick={(e) => { e.stopPropagation(); filterEVs("charging");setShow(true) }} style={{ cursor: 'pointer', }}>
        <svg className="mt-2 text-danger" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#40454d">
            <g><circle cx="12" cy="12" r="8" fill="#40454d"></circle></g>
        </svg>
        <div className="ms-2">
            <span className="mb-3">Charging now </span>
            
        </div>
    </div>
</div>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <ChartsAndCards/>
            
              
            </Row>

       

            <Offcanvas  style={{width:"35%"}} show={show} onHide={() => setShow(false)} placement={`${themeSchemeDirection === "rtl" ? 'start' : 'end'}`} backdrop={false} scroll={true} className="live-customizer">
                <Offcanvas.Header closeButton>
               
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                     <h2>SOC</h2>
                        <Col md={12}>
                        {evsStatus === "charged" ? <h3 style={{color : "#1cd92d"}}>Fully charged</h3>
                        :evsStatus === "recommended" ? <h3 style={{color : "#6103f2"}}>Sufficient charge</h3>
                        :evsStatus === "need" ? <h3 style={{color : "#ba4d42"}}>Charging needed</h3>
                       : <h3 style={{color : "#40454d"}}>Charging now</h3>
                    }

                            <div className="border border-2 rounded">
                                
                            <Tab.Container defaultActiveKey="first">
                        
                        <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                            <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                                <div className="table-responsive">
                                    <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                        <tbody>
                                        <tr>
                                            <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Vehicles</h6>
                                                    </div>
                                                </td>
                                                
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Plate Number</h6>
                                                    </div>
                                                </td>
                                                
                                                
                                              
                                             

                                                
                                            </tr>
                                            <div className="tab-bottom-bordered"></div>
                                            {filtredEVs.map((vehicle)=>(
  <tr>
  <td>
          <div className="d-flex align-items-center">
          <img src={car} style={{ height: "30px", width: "50px",marginTop:"2px" ,cursor:"pointer" }} onClick={()=>selectEvProfile(vehicle)} />
          <Col>
          <h6 className="mb-0">{vehicle.make+" "+vehicle.model}</h6>
          <span className={`badge rounded-pill`} style={{backgroundColor:` ${
                                                                 vehicle.soc >= 0.8 && vehicle.chargingStatus!=="Charging" ? '#1cd90d'
                                                                : vehicle.soc >= 0.4 && vehicle.chargingStatus!=="Charging" ? '#6103f2'
                                                                : vehicle.chargingStatus==="Charging" ? '#40454d'
                                                                :  '#ba4d42'
                                                                }`}}>{handlePercentage(vehicle.soc)}</span>
                      {/* <span className={`badge rounded-pill bg-success`}>30%</span> */}

          </Col>

          </div>
      </td>
      
      <td>
          <div className="d-flex align-items-center">
              <h6 className="mb-0">{vehicle.plateNumber}</h6>
          </div>
      </td>
      
      
    
     

      
  </tr>
                                            ))}
                                          

                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                         
                           
                        </Tab.Content>
                    </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    )
}
)

Index.displayName = "Index"
export default Index
