import axios from 'axios'; // Replace with equivalent library for making HTTP requests in React
// import Config from 'dotenv'; // Replace with equivalent library for environment variables in React

// Load environment variables from .env file (if using dotenv)
// Config.config();

export const request = axios.create({
  baseURL: process.env.REACT_APP_LOCAL_DEV_URL  , // Replace with equivalent variable or value for the baseURL
  timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT) // Replace with equivalent variable or value for the timeout
});
