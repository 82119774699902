import { useState, useEffect, memo, Fragment } from 'react';
import { Row, Col, Dropdown, Button, Form, Table, Card, Tab, Offcanvas, Nav } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import * as SettingSelector from '../../store/setting/selectors';
import car from "../../assets/images/cars/Tesla-Model-3.png";
import {setAllDrivers} from "../../store/Account/accountSlice";
import {editCars} from "../../store/Car/carSlice"
import {createDriver} from '../../store/Account/actions'


const AddDrivers = memo((props) => {
  
  const dispatch = useDispatch()
  useSelector(SettingSelector.theme_color);
  const themeSchemeDirection = useSelector(SettingSelector.theme_scheme_direction);
  const { allCars } = useSelector((state) => state.car);
  const allDrivers = useSelector(state => state.account.allDrivers);

  const getVariableColor = () => {
    let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
    const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
    const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
    const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const [show, setShow] = useState(false);
  const [filtredEVs,setFiltredEVs] = useState(allCars.filter((car)=>car.currentUser ===null))
  const [selectedDriverId,setSelectedDriverId] = useState();
  const [drivers, setDrivers] = useState([
    {
     firstName: '',
        lastName: '',
        email: '',
        contact: '',
        driverLicense:'',
        position: '',
        currentVehicule : null,
    },
  ]);

  useEffect(() => {
    setFiltredEVs(allCars.filter((car)=> car.currentUser === null))
    }, [allCars]);
  const handleAddDriver = () => {
    setDrivers((prevDrivers) => [
      ...prevDrivers,
      {
        firstName: '',
        lastName: '',
        email: '',
        contact: '',
        driverLicense:'',
        position: '',
        currentVehicule : null,
      },
    ]);
  };

  const handleAssignButton = (driverId) => {
    setSelectedDriverId(driverId);
    setShow(true);


};
  const assignCarToDriver = (car) => {
    setDrivers((prevDrivers) => {
      const updatedDrivers = [...prevDrivers];
      updatedDrivers[selectedDriverId].currentVehicule = car;
     
      return updatedDrivers;
    });
    const newDriver = allDrivers[selectedDriverId];
    dispatch(editCars({car,driver : newDriver}));


    setShow(false);
  }

  const handleDriverChange = (index, field, value) => {
    setDrivers((prevDrivers) => {
      const updatedDrivers = [...prevDrivers];
      updatedDrivers[index][field] = value;
      return updatedDrivers;
    });
  };

  const handleSubmit = async () => {

    try {
     /*await Promise.all(
      drivers.map((driver) => {
        return new Promise((resolve, reject) => {
          dispatch(
            createDriver({
              onSuccess: (result) => {
                resolve(result);
              },
              driver,
              onFailure: (e) => {
                reject(e);
              },
            })
          );
        });
      })
    );*/
    drivers.forEach((employee, index) => {
      employee.id = allDrivers.length + 1;
      employee.historicalVehicules = [employee.currentVehicule]
    console.log(employee)
    }) ;
    dispatch(setAllDrivers([...allDrivers, ...drivers]));

    setDrivers([
      {
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        driverLicense: "",
        position: "",
        currentVehicle: null,
      },
    ]);
  } catch (error) {
    console.log("Error creating drivers:", error);
  }
};


    // Perform any necessary actions with the driver data
 
  const handleDeleteDriver = (index) => {
    setDrivers((prevDrivers) => {
      const updatedDrivers = [...prevDrivers];
      updatedDrivers.splice(index, 1);
      return updatedDrivers;
    });
  };
  const handlePercentage = (percentage) => {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  
    const formattedPercentage = formatter.format(percentage);
  
    return formattedPercentage;
  };
  return (
    <Fragment>
                    <h3>Drivers&gt; <u>Add Driver</u></h3>

      <Col lg="60" md="12">
        <Card className="card-block ">
          <Col>
            <Tab.Container defaultActiveKey="first">
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                  <div className="table-responsive">
                    <table id="basic-table" className="table mb-0 table-striped" role="grid">
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center light-text">
                              <h6 className="mb-0 light-text">First Name</h6>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">last Name</h6>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">Email</h6>
                            </div>
                          </td>
                          <td>
                          <div className="d-flex align-items-center">
                              <h6 className="mb-0">Contact</h6>
</div>   
</td>   <td>
                          <div className="d-flex align-items-center">
                              <h6 className="mb-0">Driver license</h6>
</div>
</td>
<td>
<div className="d-flex align-items-center">
<h6 className="mb-0">Position</h6>
</div>
</td>
<td>
<div className="d-flex align-items-center">
<h6 className="mb-0">Assign EV</h6>
</div>
</td>
</tr>
<div className="tab-bottom-bordered"></div>
{drivers.map((driver, index) => (
<tr key={index}>
<td>
<div className="d-flex light-text">
<Form.Control
type="text"
className="form-control"
placeholder="firstName"
value={driver.firstName}
onChange={(e) => handleDriverChange(index, 'firstName', e.target.value)}
/>
</div>
</td>
<td>
<div className="d-flex align-items-center">
<Form.Control
type="text"
className="form-control"
placeholder="lastName"
value={driver.lastName}
onChange={(e) => handleDriverChange(index, 'lastName', e.target.value)}
/>
</div>
</td>
<td>
<div className="d-flex align-items-center">
<Form.Control
type="email"
className="form-control"
placeholder="Email"
value={driver.email}
onChange={(e) => handleDriverChange(index, 'email', e.target.value)}
/>
</div>
</td>
<td>
<div className="d-flex align-items-center">
<Form.Control
type="text"
className="form-control"
placeholder="Contact"
value={driver.contact}
onChange={(e) => handleDriverChange(index, 'contact', e.target.value)}
/>
</div>
</td>
<td>
<div className="d-flex align-items-center">
<Form.Control
type="text"
className="form-control"
placeholder="Driver license"
value={driver.driverLicense}
onChange={(e) => handleDriverChange(index, 'driverLicense', e.target.value)}
/>
</div>
</td>
<td>
<div className="d-flex align-items-center">
<Form.Control
type="text"
className="form-control"
placeholder="Position"
value={driver.position}
onChange={(e) => handleDriverChange(index, 'position', e.target.value)}
/>
</div>
</td>
<td>
<div className="d-flex align-items-center">
  {
    !driver.currentVehicule ?(
      <h6 className='text-primary' style={{cursor:"pointer"}}><u onClick={()=>handleAssignButton(index)}>
      Choose an EV</u></h6>

    ):(
      <div className="d-flex align-items-center" style={{cursor:"pointer"}} onClick={()=>handleAssignButton(index)}>
<h6 className="mb-0">{driver?.currentVehicule?.make+" "+ driver?.currentVehicule?.model}</h6>
</div>
      
    )

  }
</div>
</td><td>
{index !== 0 && (
  <div className="d-flex align-items-center">
    <div onClick={() => handleDeleteDriver(index)} style={{ marginLeft: "5px", cursor: "pointer", border: `1px  ${variableColors.primary}`, borderRadius: "5px", padding: "2px 3px", color: "red" }}>X</div>
  </div>
)}

</td>
</tr>
))}
<div className="tab-bottom-bordered"></div>
</tbody>
</table>
<div className="d-flex align-items-center mt-1 "> {/* With left margin */}
  <div onClick={handleAddDriver} style={{marginLeft : "5px", cursor: "pointer", border: `0px solid ${variableColors.primary}`, borderRadius: "5px", padding: "2px 3px" }}>+ Add another driver</div>
</div>

<div className="d-flex justify-content-center mt-1 mb-3"> 
  <Button onClick={handleSubmit}>Submit</Button>
</div>
                    </div>
</Tab.Pane>
</Tab.Content>
</Tab.Container>

</Col>
</Card>
</Col>
<Offcanvas  show={show} style={{width : "35%"}} onHide={() => setShow(false)} placement={`${themeSchemeDirection === "rtl" ? 'start' : 'end'}`} backdrop={false} scroll={true} className="live-customizer">
              
                <Offcanvas.Header closeButton>
                  
                </Offcanvas.Header>

                <Offcanvas.Body>
                <h3 style={{marginLeft:"10px"}}>Unassigned cars</h3>

                    <Row>
                     
                        <Col md={12} >
                            <div className="border border-2 rounded" >
                            <Tab.Container defaultActiveKey="first">
                        
                        <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                            <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                                <div className="table-responsive">
                                    <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                        <tbody>
                                        <tr>
                                            <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Vehicles</h6>
                                                    </div>
                                                </td>
                                                
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Plate Number</h6>
                                                    </div>
                                                </td>
                                                
                                                
                                              
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Brand</h6>
                                                    </div>
                                                </td>

                                                
                                            </tr>
                                            <div className="tab-bottom-bordered"></div>
                                            {filtredEVs.map((vehicle)=>(
                                             
  <tr >
    
  <td>
          <div className="d-flex align-items-center">
          <img src={vehicle.imgUrl} style={{ height: "30px", width: "50px",marginTop:"2px",cursor:"pointer" }} onClick={()=>assignCarToDriver(vehicle)}/>
          <Col>
          <h6 className="mb-0">{vehicle.assigned}</h6>
          <span className={`badge rounded-pill`} style={{backgroundColor:` ${
                                                                 vehicle.soc >= 0.8 && vehicle.chargingStatus!=="Charging" ? '#1cd90d'
                                                                : vehicle.soc >= 0.5 && vehicle.chargingStatus!=="Charging" ? '#6103f2'
                                                                : vehicle.chargingStatus==="Charging" ? '#40454d'
                                                                :  '#ba4d42'
                                                                }`}}>{handlePercentage(vehicle.soc)}</span>
                      {/* <span className={`badge rounded-pill bg-success`}>30%</span> */}

          </Col>

          </div>
      </td>
      
      <td>
          <div className="d-flex align-items-center">
              <h6 className="mb-0">{vehicle.plateNumber}</h6>
          </div>
      </td>
      
      
    
      <td>
          <div className="d-flex align-items-center">
              <h6 className="mb-0">{vehicle.make+" " + vehicle.model}</h6>
          </div>
      </td>

      
  </tr>
                                            ))}
                                          

                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                         
                           
                        </Tab.Content>
                    </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
</Fragment>
);
});

AddDrivers.displayName = 'AddDrivers';
export default AddDrivers;
