import React from 'react';
import { Modal, Card, Tab, Col, Image } from 'react-bootstrap';
import moment from 'moment';


function AssignCarsHistoryModal({ isModalVisible, setIsModalVisible, AssignedDriverHistoryTable }) {
  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal show={isModalVisible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>All Assigned cars history</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <Card className="card-block"  style={{ height: "320px", overflow: "scroll", marginBottom: "10px",marginTop:"-2.4%" }}>
                        <Tab.Container defaultActiveKey="first">
                       
                            <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">

                                <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                                    <div className="table-responsive">
                                        <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                            <tbody>
                                            
                                    {
                                        AssignedDriverHistoryTable?.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <Image className="img-fluid w-35 me-3" src={item.imgUrl} alt="" style={{ borderRadius: "5px",height:"65px",width:"100px" }} />
                                                  <div className="media-support-info">
                                                  <div className="d-flex justify-content-between align-items-center" style={{width:"80%"}}>
  <h6 className="mb-0">{item.make+" "+item.model}</h6>
  {index === 0 && <span className="badge rounded-pill bg-primary">current</span>}
  
</div>                                                    <p className="mb-0">{item.status}</p>
  <div className=" w-100" style={{ height: "20px" }}>
                                                <h6 className="text-body">{moment(item.assignedDate).format('DD-MMMM')} to  {index===0 ? "present" : "March 29th"}</h6>
                                            </div>                 
                                                  </div>
                                                </div>
                                              </td>
                                              
                                              {/* Render other table cells as needed */}
                                            </tr>
                                          )
                                        })
                                      }
                                      
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab.Pane>
                           
                               
                            </Tab.Content>
                        </Tab.Container>
                      
                    </Card>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AssignCarsHistoryModal;
