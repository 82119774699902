import React, { useEffect } from 'react';
const { tableau } = window;

const VizualisationComponent = ({url}) => { 
  
 

  return <div>

  <tableau-viz id="tableauViz" src={url}
      hide-tabs toolbar="hidden">
  </tableau-viz>
</div>;

};

export default VizualisationComponent;