import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { theme_color, theme_scheme_direction } from '../../store/setting/selectors';

const SettingsSection = memo(() => {
  const dispatch = useDispatch();
  const themeColor = useSelector(theme_color);
  const themeSchemeDirection = useSelector(theme_scheme_direction);

  const [inputsStatus, setInputsStatus] = useState(false);

  const [formData, setFormData] = useState({
    companyName: 'Youree',
    companyEmail: 'support@youree.io',
    adminUserName: 'Admin',
    password: '',
    language: 'English',
  });

  useEffect(() => {
    const variableColors = getVariableColor();
    document.body.style.setProperty('--prefix', 'bs-');
    document.body.style.setProperty('--bs-primary', variableColors.primary);
    document.body.style.setProperty('--bs-info', variableColors.info);
    document.body.style.setProperty('--bs-warning', variableColors.warning);
    document.body.style.setProperty('--bs-primary-tint-20', variableColors.primary_light);
  }, []);

  const getVariableColor = () => {
    const prefix = getComputedStyle(document.body).getPropertyValue('--prefix').trim() || 'bs-';
    const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`).trim();
    const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`).trim();
    const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`).trim();
    const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`).trim();

    return {
      primary: color1,
      info: color2,
      warning: color4,
      primary_light: color3,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const newData = {
      companyName: formData.get('companyName'),
      companyEmail: formData.get('companyEmail'),
      adminUserName: formData.get('adminUserName'),
      password: formData.get('password'),
      language: formData.get('language'),
    };
    setFormData(newData);
    setInputsStatus(false); // Hide the form inputs after submission
  };

  const handlePasswordClick = () => {
    setInputsStatus(true);
  };

  const handleCancelClick = () => {
    setInputsStatus(false);
  };

  return (
    <React.Fragment>
      <h3 style={{ marginLeft: '25%' }}>Settings &gt; Profile</h3>

      <Row className="justify-content-center" lg="6" md="12">
        <Col lg="6" md="15">
          <Card className="card-block col-18">
            <Col style={{ marginLeft: '25%' }}>
          
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '1rem',
                }}
              >
              
                <h4>Company Account Information</h4>

              </div>
              <Form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '1rem' }}>
              {
                  !inputsStatus ? (
                    <>
                           <h5>Company Name</h5>
                  <Form.Control
                    type="text"
                    style={{ width: '60%', backgroundColor:  '#F0F0F0' }}
                    readOnly={true}
                    name="companyName"
                    defaultValue={formData.companyName}
                    id="floatingInput2"
                    autoComplete="username email"
                    placeholder="Place Holder"
                  />
                  <h5>Company Email</h5>
                  <Form.Control
                    type="email"
                    style={{ width: '60%', backgroundColor:  '#F0F0F0' }}
                    readOnly={true}
                    name="companyEmail"
                    defaultValue={formData.companyEmail}
                    id="floatingInput2"
                    autoComplete="username email"
                    placeholder="Place Holder"
                  />
                  <h5>Admin Username</h5>
                  <Form.Control
                    type="text"
                    style={{ width: '60%', backgroundColor:  '#F0F0F0' }}
                    readOnly={true}
                    name="adminUserName"
                    defaultValue={formData.adminUserName}
                    id="floatingInput2"
                    autoComplete="username email"
                    placeholder="Place Holder"
                  />
                    <h5>Language</h5>
                  <Form.Control
                    as="select"
                    style={{ width: '60%', backgroundColor:  '#F0F0F0' }}
                    className=""
                    readOnly={true}
                    name="language"
                    defaultValue={formData.language}
                    id="floatingInput2"
                  >
                    <option value="English">English</option>
                    <option value="Spanish">Spanish</option>
                    <option value="French">French</option>
                  </Form.Control>
                  <div style={{marginTop :"10px"}}>

<span
style={{
  fontSize: "15px",
  textDecoration: 'underline',
  cursor: 'pointer',
  color: 'black'
}}
 onClick={() => setInputsStatus(true)}
>
Change Password ?
</span>
</div>

                    </>
                  ):
                  (<>
     <React.Fragment>
                      <h5>Current Password</h5>
                      <Form.Control
                        type="password"
                        style={{ width: '60%' }}
                        className=""
                        readOnly={!inputsStatus}
                        name="currentPassword"
                        defaultValue=""
                        id="floatingInput2"
                        autoComplete="current-password"
                        placeholder="Place Holder"
                      />
                      <h5>New Password</h5>
                      <Form.Control
                        type="password"
                        style={{ width: '60%' }}
                        className=""
                        readOnly={!inputsStatus}
                        name="newPassword"
                        defaultValue=""
                        id="floatingInput2"
                        autoComplete="new-password"
                        placeholder="Place Holder"
                      />
                      <h5>Repeat New Password</h5>
                      <Form.Control
                        type="password"
                        style={{ width: '60%' }}
                        className=""
                        readOnly={!inputsStatus}
                        name="repeatNewPassword"
                        defaultValue=""
                        id="floatingInput2"
                        autoComplete="new-password"
                        placeholder="Place Holder"
                      />
                    </React.Fragment>
                  </>)
                }
              
           

                
                  {inputsStatus && (
                    <div
                      style={{
                        width: '200px',
                        marginTop: '10px',
                        marginLeft: '30px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button type="submit">Submit</Button>
                      <Button variant="secondary" onClick={handleCancelClick}>
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            </Col>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
});

SettingsSection.displayName = 'SettingsSection';
export default SettingsSection;
