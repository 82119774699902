import React from 'react';
import { Modal, Card, Tab, Col, Image } from 'react-bootstrap';

function AssignedDriverHistoryModal({ isModalVisible, setIsModalVisible, AssignedDriverHistoryTable }) {
  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal show={isModalVisible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>All Assigned driver's history</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
      <Card className="card-block" style={{ overflow: "hidden" }}>
            <Tab.Container defaultActiveKey="first">
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
              <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                  <div className="table-responsive" >
                  
                      {AssignedDriverHistoryTable.map((item, index) => (
                    <div key={item.id} className="table-responsive" style={{ backgroundColor: item.backgroundColor }}>

<table id="basic-table" className="table mb-0 table-striped" role="grid">
                          <tbody>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <Image className="img-fluid w-35 me-3" src={item.profilPic} alt="" style={{ borderRadius: "50px" }} />
                                <div className="media-support-info">
                                  <div className="d-flex justify-content-between align-items-center" style={{ width: "80%" }}>
                                    <h6 className="mb-0">{item.firstName + " " + item.lastName}</h6>
                                    {index === 0 && <span className="badge rounded-pill bg-primary">current</span>}
                                  </div>
                                  <div className="w-100" style={{ height: "20px" }}>
                                    <h6 className="text-body">March 13th - March 29th</h6>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                    </table>
                    </div>
                        ))}
                     
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AssignedDriverHistoryModal;
