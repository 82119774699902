import { createAsyncThunk, createAction, isRejectedWithValue } from "@reduxjs/toolkit";
import { useSelector } from 'react-redux';


export const setAllCars = createAction("car/setCars", function (cars) {
  return {
    payload: cars
  };
});
export const editCars = createAction("car/editCars", function (car,driver) {
  return {
    payload: {car: car, driver: driver}
  };
});
export const setSelectedCarProfile = createAction("car/setSelectedCar", function (vehicle) {

  return {
    payload: {vehicle}
  };
});
export const fetchAllVehicles = createAsyncThunk(
  "vehicle/fetchAllVehicles",
  async (_, { dispatch }) => {
    try {
      const { result, error } = await dispatch(fetchAllVehiclesApi());
      if (error) {
        throw new Error(error);
      }
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAllVehiclesApi = createAction("vehicle/fetchAllVehiclesApi", function prepare() {
  return {
    payload: {
      url: "/api/v1/vehicles",
      method: "GET",
    },
  };
});

export const addVehicle = createAsyncThunk(
  "vehicle/addVehicle",
  async ({ onSuccess, data, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(addVehicleApi(data));

      if (error) {
        onFailure(error);
        return;
      }

      onSuccess(result.data);
      return result.data;
    } catch (err) {
      onFailure(err);
      return err;
    }
  }
);

export const addVehicleApi = createAction("vehicle/addVehicleApi", function prepare(data) {
  return {
    payload: {
      url: "/api/v1/vehicles/vehicle",
      method: "POST",
      body: data,
    },
  };
});
export const updateVehicle = createAsyncThunk(
  "vehicle/updateVehicle",
  async ({ onSuccess, vehicleid, updatedVehicle, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(updateVehicleApi({vehicleid, updatedVehicle}));

      if (error) {
        onFailure(error);
        return;
      }

      onSuccess(result.data.data);
      return result.data.data;
    } catch (err) {
      onFailure(err);
      return err;
    }
  }
);

export const updateVehicleApi = createAction("vehicle//updateVehicleApi", function prepare({ vehicleid, updatedVehicle}) {
  return {
    payload: {
      url: `/api/v1/vehicles/${vehicleid}`,
      method: "PUT",
      body: updatedVehicle,
    },
  };
});
export const fetchVehiclesByCompany = createAsyncThunk(
  "vehicle/fetchVehiclesByCompany",
  async ({ companyId, dispatch }) => {
    try {
      const { result, error } = await dispatch(fetchVehiclesByCompanyApi(companyId));

      if (error) {
        throw new Error(error);
      }

      return result.data;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchVehiclesByCompanyApi = createAction("vehicle/fetchVehiclesByCompanyApi", function prepare(companyId) {
  return {
    payload: {
      url: `/api/v1/vehicles/company/${companyId}`,
      method: "GET",
    },
  };
});

export const findVehicleByVin = createAsyncThunk(
  "vehicle/findVehicleByVin",
  async ({ onSuccess, carVin, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(findVehicleByVinApi(carVin));

      if (error) {
        onFailure(error);
        return;
      }

      onSuccess(result.data);
      return result.data;
    } catch (err) {
      onFailure(err);
      return err;
    }
  }
);

export const findVehicleByVinApi = createAction("vehicle/findVehicleByVinApi", function prepare(carVin) {
  return {
    payload: {
      url: `/api/v1/vehicles/vehicle/${carVin}`,
      method: "GET",
    },
  };
});

export const findVehicleById = createAsyncThunk(
  "vehicle/findVehicleById",
  async ({ onSuccess, id, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(findVehicleByIdApi(id));

      if (error) {
        onFailure(error);
        return id;
      }

      onSuccess(result.data);
      return id;
    } catch (err) {
      onFailure(err);
      return id;
    }
  }
);

export const findVehicleByIdApi = createAction("vehicle/findVehicleByIdApi", function prepare(id) {
  return {
    payload: {
      url: `/api/v1/vehicles/vehicle/${id}`,
      method: "GET",
    },
  };
});

export const getAllVehiclesByUserId = createAsyncThunk(
  "vehicle/getAllVehiclesByUserId",
  async ({ onSuccess, userId, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(getAllVehiclesByUserIdApi(userId));

      if (error) {
        onFailure(error);
        return;
      }

      onSuccess(result.data);
      return result.data;
    } catch (err) {
      onFailure(err);
      return err;
    }
  }
);

export const getAllVehiclesByUserIdApi = createAction("vehicle/getAllVehiclesByUserIdApi", function prepare(userId) {
  return {
    payload: {
      url: `/api/v1/vehicles/user/${userId}`,
      method: "GET",
    },
  };
});