import React, { useState } from 'react';
import { Col, Button, Form, Card, InputGroup } from 'react-bootstrap';

const ChargingAddressCard = ({ data, variableColors }) => {
    const [isFormEditable, setFormEditable] = useState(false);
    const [offPeakEntries, setOffPeakEntries] = useState(data.offPeakCosts || []);
    const [addressData, setAddressData] = useState(data);

    const handleSubmit = e => {
        e.preventDefault();
        setFormEditable(false);
        console.log('Form submitted with data:', addressData, offPeakEntries);
    };

    const handleInputChange = (field, value) => {
        setAddressData(prevAddressData => ({
            ...prevAddressData,
            [field]: value,
        }));
    };

    const handleAddOffPeak = () => {
        const newOffPeak = [...offPeakEntries, { cost: '', from: '', to: '' }];
        setOffPeakEntries(newOffPeak);
        setAddressData(prevAddressData => ({
            ...prevAddressData,
            offPeakCosts: newOffPeak,
        }));
    };

    const handleOffPeakChange = (index, field, value) => {
        const updatedOffPeakEntries = [...offPeakEntries];
        updatedOffPeakEntries[index][field] = value;
        setOffPeakEntries(updatedOffPeakEntries);
    };

    const handleContractTypeChange = newContractType => {
        setAddressData(prevAddressData => ({
            ...prevAddressData,
            contractType: newContractType,
            offPeakCosts: newContractType === 'fixed' ? null : offPeakEntries,
        }));
        if (newContractType === 'fixed') {
            setOffPeakEntries([]);
        }
    };

    return (
        <Card className="card-block col-18">
            <Col>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', width: '95%', marginLeft: '3%', marginTop: '2%' }}>
                    <h4>{addressData.name}</h4>
                    <div style={{ border: '0px solid #000', borderRadius: '35%', cursor: 'pointer' }} onClick={() => setFormEditable(true)}>
                        <h5 style={{ color: variableColors.primary }}><u>Modify</u></h5>
                    </div>
                </div>
                <Form onSubmit={handleSubmit}>
                    <div style={{ marginLeft: '5%', marginTop: '5%', marginBottom: '10%' }}>
                        <InputGroup className="mb-3" style={{ width: '50%' }}>
                            <Form.Control
                                type="text"
                                className=""
                                readOnly={!isFormEditable}
                                name="companyName"
                                value={addressData.name}
                                onChange={e => handleInputChange("name", e.target.value)}
                                id="floatingInput2"
                                placeholder="Place Holder"
                            />
                        </InputGroup>
                        {isFormEditable === true && (
                            <div style={{ marginTop: "2%" }}>
                                <h5>Contract type</h5>
                                <Button type="button" variant={addressData.contractType === "fixed" ? "secondary" : "light"} onClick={() => handleContractTypeChange('fixed')}>Fixed</Button>{' '}
                                <Button type="button" variant={addressData.contractType === "variable" ? "secondary" : "light"} onClick={() => handleContractTypeChange('variable')}>Variable</Button>{' '}
                            </div>
                        )}
                        <h5 style={{ marginTop: "2%" }}>Variable cost contract</h5>
                        <p>Peak Cost</p>
                        <InputGroup className="mb-3" style={{ width: '20%' }}>
                            <Form.Control
                                readOnly={!isFormEditable}
                                type="text"
                                className=""
                                placeholder="0.157"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                inputMode="numeric"
                                pattern="[0-9]*\.?[0-9]+"
                                value={addressData.peakCost.value}
                                onChange={e => handleInputChange("peakCost", e.target.value)}
                            />
                            <span className="input-group-text" id="basic-addon2">€/kWh</span>
                        </InputGroup>
                        {offPeakEntries.map((entry, index) => (
                            <div key={index}>
                                <p>Off-Peak Cost {index + 1}</p>
                                <InputGroup className="mb-3" style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                                    <Form.Control
                                        readOnly={!isFormEditable}
                                        type="text"
                                        className=""
                                        placeholder="0.157"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        inputMode="numeric"
                                        pattern="[0-9]*\.?[0-9]+"
                                        style={{ flex: 1 }}
                                        value={offPeakEntries[index].cost}
                                        onChange={e => handleOffPeakChange(index, "cost", e.target.value)}
                                    />
                                    <span className="input-group-text" id="basic-addon2" >€/kWh</span>
                                    <p style={{ margin: '0 5px' }}>from</p>
                                    <Form.Control
                                        readOnly={!isFormEditable}
                                        type="text"
                                        className=""
                                        placeholder="hh:mm"
                                        aria-label="From"
                                        aria-describedby="basic-addon2"
                                        pattern="(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]"
                                        style={{ width: '80px', marginRight: '5px' }}
                                        value={offPeakEntries[index].from}
                                        onChange={e => handleOffPeakChange(index, "from", e.target.value)}
                                    />
                                    <p style={{ margin: '0 5px' }}>to</p>
                                    <Form.Control
                                        readOnly={!isFormEditable}
                                        type="text"
                                        className=""
                                        placeholder="hh:mm"
                                        aria-label="To"
                                        aria-describedby="basic-addon2"
                                        pattern="(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]"
                                        style={{ width: '80px' }}
                                        value={offPeakEntries[index].to}
                                        onChange={e => handleOffPeakChange(index, "to", e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        ))}
                        {isFormEditable && addressData.contractType === "variable" && (
                            <Button variant="secondary" onClick={handleAddOffPeak}>Add Off-Peak</Button>
                        )}
                     
                    </div>
                    {isFormEditable && (
                            <div style={{display:"flex",marginBottom:"3%",alignContent:"space-around",alignItems:"center",marginLeft:"60%",width:"50%"}}>
                                <Button type="button" variant="light" onClick={() => setFormEditable(false)}>Cancel</Button>
                                <Button type="submit" variant="light" style={{marginLeft:"4%",width:"50%"}}>Submit</Button>
                            </div>
                        )}
                </Form>
            </Col>
        </Card>
    );
};

export default ChargingAddressCard;
