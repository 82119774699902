import { createSlice } from "@reduxjs/toolkit";
import {
  signin,
  createDriver,
  fetchAllDrivers,
  updateDriver,
  deleteDriver,
  fetchDriverById

} from "./actions";
import drivers from "../../data/data";
const chargingAddresses = [
  {
      name: "Wojo Champs Elysées Parking",
      contractType: "fixed",
      peakCost: {
          value: "0.157",
          unit: "€/kWh"
      }
  },
  {
      name: "Another Charging Address",
      contractType: "variable",
      peakCost: {
          value: "0.157",
          unit: "€/kWh"
      },
      offPeakCosts: [
          {
              value: "0.123",
              unit: "€/kWh",
              from: "08:00",
              to: "18:00"
          },
          {
              value: "0.090",
              unit: "€/kWh",
              from: "18:00",
              to: "08:00"
          }
      ]
  }
];
// import drivers from "../../data/data.json";


const accountSlice = createSlice({
  name: "account",
  initialState: {
    profile: null,
    companyId: null,
    pending: false,
    login: false,
    token: null,
    email: null,
    shortToken: null,
    user: null,
    carInfo: null,
    currentScreen: "connectOEM",
    searchScreen: true,
    provider: null,
    step: 2,
    OemSteps: 1,
    applicationInformations: null,
    deepLinkSource: null,
    deleteAccountRequest: null,
    smartChargingStatus: null,
    address: "",
    enedisLink: "",
    defaultValueConfirmed: false,
    sessionsData: [],
    allDrivers: drivers.scappamUsers,
    selectedDriverProfile: [],
    isAuthenticated: false,
    chargingAddresses: chargingAddresses,
  },
  reducers: {
    setAllDrivers: (state, action) => {
      state.allDrivers = action.payload;
    },
    setSelectedDriver: (state, action) => {
      state.selectedDriverProfile = action.payload;
    },
    editDriver: (state, action) => {
      const driverId = action.payload.driver.id;
      const updatedDrivers = state.allDrivers;
      const driverIndex = updatedDrivers.findIndex((driver) => driverId === driver.id);
      const newDriver = { ...updatedDrivers[driverId] };
      newDriver.hasAssignedCar = true;
      newDriver.assigned = action.payload.car.name;
      newDriver.assignedId = action.payload.car.id;
      newDriver.plateNumber = action.payload.car.plateNumber;
      updatedDrivers[driverIndex] = newDriver;
      state.allDrivers = updatedDrivers;
    },
    logout: (state, action) => {
      localStorage.removeItem("auth-token");
      state.isAuthenticated = false;
      console.log("user logged out");
    },
  },
  extraReducers: {
    [signin.pending]: (state, { payload }) => {
      localStorage.setItem("auth-token", "test");
      state.isAuthenticated = true;
    },
    [signin.fulfilled]: (state, { payload }) => {
      // if (payload) {
      //   state.profile = payload?.account;
      //   state.token = payload?.oAuthIdpTokenResponseDTO?.access_token;
      //   localStorage.setItem("auth-token", payload.oAuthIdpTokenResponseDTO.access_token);
      //   state.isAuthenticated = true;
      // }
   
      localStorage.setItem("auth-token", "test");
      state.isAuthenticated = true;
    },
    [signin.rejected]: (state, { payload }) => {
      localStorage.setItem("auth-token", "test");
      state.isAuthenticated = true;
      state.message = "failed";
      state.profile = {};
    },
    [createDriver.fulfilled]: (state, { payload }) => {
      console.log("fulfilled", payload);

      state.allDrivers= [...state.allDrivers, payload.driver]
      // Handle successful createDriver action
      // Add the newly created driver to the state
    },
    [createDriver.pending]: (state, { payload }) => {
      console.log("pending", payload);
    },
    [createDriver.rejected]: (state, { payload }) => {
     console.log(payload)
    },
    [fetchAllDrivers.fulfilled]: (state, { payload }) => {
      console.log(payload,"fulfilled")
      state.allDrivers = payload? payload : drivers?.scappamUsers;
    },
    [fetchAllDrivers.pending]: (state, { payload }) => {
      console.log("pending", payload);
    },
    [fetchAllDrivers.rejected]: (state, { payload }) => {
     console.log(payload)
    },
    [fetchDriverById.fulfilled]: (state, { payload }) => {
      console.log(payload,"fulfilled")
      state.allDrivers = payload? payload : drivers?.scappamUsers;
    },
    [fetchDriverById.pending]: (state, { payload }) => {
      console.log("pending", payload);
    },
    [fetchDriverById.rejected]: (state, { payload }) => {
     console.log(payload)
    },
    [updateDriver.fulfilled]: (state, { payload }) => {
      // Handle successful updateDriver action
      // Update the relevant driver in the state
    },
    [deleteDriver.fulfilled]: (state, { payload }) => {
      // Handle successful deleteDriver action
      // Remove the deleted driver from the state
    },

  },
});

export const { setAllDrivers, setSelectedDriver, editDriver, logout } = accountSlice.actions;
export default accountSlice;
