import { createAsyncThunk, createAction, isRejectedWithValue } from "@reduxjs/toolkit";



export const createDriverApi = createAction("account/createDriverApi", function prepare(drivers) {
  return {
    payload: {
      url: "/api/v1/drivers",
      method: "POST",
      body: drivers,
    },
  };
});
export const createDriver = createAsyncThunk("account/createDriver",
 async ({ onSuccess, driver, onFailure }, { dispatch }) => {
  try {
    const { result, error } = await dispatch(createDriverApi(driver))
    if (error) {
      console.log(error);
      onFailure(error);
      return;
    }
    
console.log(result,"created driver")
    onSuccess(result.data.data);
    return result.data.data
  } catch (err) {
    console.log(err)
    onFailure(err);
    return err;
  }
});



export const fetchAllDrivers = createAsyncThunk(
  "account/fetchAllDrivers",
  async ({ onSuccess, onFailure },{ dispatch }) => {
try {
  const { result, error } = await dispatch(fetchAllDriversApi())
  if (error) {
    console.log(error);
    onFailure(error);
    return;
  }
  
console.log(result.data,"created driver")
  onSuccess(result.data);
  return result.data
} catch (err) {
  console.log(err)
  onFailure(err);
  return err;
}
    //   if (error) {
    //     console.log(error)
    //     onFailure(error);
    //     return;  
    //   }
    //   onSuccess(result.data.data);
    //   console.log(result.data.data,"daattaaa");
    //   return result.data.data
    // } catch (err) {
    //   onFailure(err);
    //   return err;
     
  }
);

export const fetchAllDriversApi = createAction("account/fetchAllDriversApi", function prepare() {
  return {
    payload: {
      url: "/api/v1/drivers",
      method: "GET",
    },
  };
});

export const fetchDriverById = createAsyncThunk(
  'account/fetchDriverById',
  async ({ accountId, onSuccess, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(fetchDriverByIdApi(accountId));
      if (error) {
        console.log(error);
        onFailure(error);
        return;
      }

      console.log(result.data, 'fetched account');
      onSuccess(result.data);
      return "result.data";
    } catch (err) {
      console.log(err);
      onFailure(err);
      return err;
    }
  }
);

export const fetchDriverByIdApi = createAction('account/fetchDriverByIdApi', function prepare(accountId) {
  return {
    payload: {
      url: `/api/v1/accounts/${accountId}`, 
      method: 'GET',
    },
  };
});
export const signin = createAsyncThunk("account/signin",
 async ({ onSuccess, email, password, onFailure }, { dispatch }) => {
  try {
    const { result, error } = await dispatch(loginApi({ email, password }));
    
    if (error) {
      onFailure(error);
      return;
    }
    

    onSuccess(result.data.data);
    return result.data.data
  } catch (err) {
    onFailure(err);
    return err;
  }
});
export const updateDriver = createAsyncThunk(
  "account/updateDriver",
  async ({ onSuccess, driverid, newDriver, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(updateDriverApi({driverid, newDriver}));

      if (error) {
        onFailure(error);
        return;
      }

      onSuccess(result.data.data);
      return result.data.data;
    } catch (err) {
      onFailure(err);
      return err;
    }
  }
);

export const updateDriverApi = createAction("account/updateDriverApi", function prepare({ driverid, newDriver}) {
  return {
    payload: {
      url: `/api/v1/drivers/${driverid}`,
      method: "PUT",
      body: newDriver,
    },
  };
});
export const deleteDriver = createAsyncThunk(
  "account/deleteDriver",
  async ({ onSuccess, id, onFailure }, { dispatch }) => {
    try {
      const { result, error } = await dispatch(deleteDriverApi(id));

      if (error) {
        onFailure(error);
        return;
      }

      onSuccess();
      return result.data.data;
    } catch (err) {
      onFailure(err);
      return err;
    }
  }
);

export const deleteDriverApi = createAction("account/deleteDriverApi", function prepare(id) {
  return {
    payload: {
      url: `/api/v1/drivers/${id}`,
      method: "DELETE",
    },
  };
});

// export const addVehicle = createAsyncThunk(
//   "account/addVehicle",
//   async ({ onSuccess, data, onFailure }, { dispatch }) => {
//     try {
//       const { result, error } = await dispatch(addVehicleApi(data));

//       if (error) {
//         onFailure(error);
//         return;
//       }

//       onSuccess(result.data);
//       return result.data;
//     } catch (err) {
//       onFailure(err);
//       return err;
//     }
//   }
// );

// export const addVehicleApi = createAction("account/addVehicleApi", function prepare(data) {
//   return {
//     payload: {
//       url: "/api/v1/vehicles/vehicle",
//       method: "POST",
//       body: data,
//     },
//   };
// });

// export const fetchAllVehiclesByCompany = createAsyncThunk(
//   "account/fetchAllVehiclesByCompany",
//   async (_, { dispatch }) => {
//     try {
//       const { result, error } = await dispatch(fetchAllVehiclesByCompanyApi());
//       if (error) {
//         throw new Error(error);
//       }
//       return result.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

// export const fetchAllVehiclesByCompanyApi = createAction(
//   "account/fetchAllVehiclesByCompanyApi",
//   function prepare() {
//     return {
//       payload: {
//         url: "/api/v1/vehicles/company/{companyId}",
//         method: "GET",
//       },
//     };
//   }
// );

// export const findVehicleByVin = createAsyncThunk(
//   "account/findVehicleByVin",
//   async ({ onSuccess, carVin, onFailure }, { dispatch }) => {
//     try {
//       const { result, error } = await dispatch(findVehicleByVinApi(carVin));

//       if (error) {
//         onFailure(error);
//         return;
//       }

//       onSuccess(result.data);
//       return result.data;
//     } catch (err) {
//       onFailure(err);
//       return err;
//     }
//   }
// );

// export const findVehicleByVinApi = createAction("account/findVehicleByVinApi", function prepare(carVin) {
//   return {
//     payload: {
//       url: `/api/v1/vehicles/vehicle/${carVin}`,
//       method: "GET",
//     },
//   };
// });

// export const getAllVehiclesByUserId = createAsyncThunk(
//   "account/getAllVehiclesByUserId",
//   async ({ onSuccess, userId, onFailure }, { dispatch }) => {
//     try {
//       const { result, error } = await dispatch(getAllVehiclesByUserIdApi(userId));

//       if (error) {
//         onFailure(error);
//         return;
//       }

//       onSuccess(result.data);
//       return result.data;
//     } catch (err) {
//       onFailure(err);
//       return err;
//     }
//   }
// );

// export const getAllVehiclesByUserIdApi = createAction(
//   "account/getAllVehiclesByUserIdApi",
//   function prepare(userId) {
//     return {
//       payload: {
//         url: `/api/v1/vehicles/user/${userId}`,
//         method: "GET",
//       },
//     };
//   }
// );


export const loginApi = createAction("account/signinApi", function prepare(data) {
  return {
    payload: {
      url: "/api/Youree/user/login",
      method: "POST",
      body: data,
    },
  };
});
  export const setAllDrivers = createAction("account/setDrivers", function (drivers) {
    return {
      payload: drivers
    };
  });
  export const setSelectedDriver = createAction("account/setDriverProfile", function (driver) {

    return {
      payload: driver
    };
  });
  export const editDriver = createAction("account/editDriver", function (driver,car) {})
  export const logout = createAction("account/logout", function(){
  })
  
