import Card from '../components/bootstrap/card'
import { useState } from 'react';
import { memo, Fragment } from 'react';
import { Row, Col, Image, FormCheck, Form, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo/logo@2x.png';
import { useDispatch, useSelector } from 'react-redux'
import { signin } from '../store/Account/actions'


const SignIn = memo(() => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [isLoading,setIsLoading] = useState(false);
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    
    if (formData.email && formData.password) {
      console.log('Email:', formData.email);
      console.log('Password:', formData.password);
      const email = formData.email
      const password = formData.password
      dispatch(signin({
        onSuccess: (result) => {
          console.log(result)

          if (result?.account) {
  
            navigate("/dashboard")
          }
          setIsLoading(false);
          navigate("/dashboard")

         
        }, email,
        password,
        onFailure: (e) => {
          setIsLoading(false);
          navigate("/dashboard")
           console.log("error",e)
          //  setIsLoading(false)
        }
    }))
    } else {
      // Handle invalid form submission
      console.log('Please enter both email and password');
    }
  };

  const isFormValid = formData.email && formData.password;

  return (
    <Fragment>
      <section className="iq-auth-page" style={{ background: `#FFFFF9`, backgroundRepeat: 'no-repeat', position: 'relative', backgroundSize: 'cover' }}>
        <Row>
          <div className='d-flex align-items-start' style={{ marginLeft: "40px" }}>
            <div className='mr-3' style={{ marginTop: "55px" }}>
              <Image src={logo} alt="fb" width={70} height={70} />
            </div>
            <div style={{ marginTop: "55px" }}>
              <h1>Youree</h1>
              <h6>Business</h6>
            </div>
          </div>
        </Row>

        <Row className="d-flex align-items-center justify-content-center vh-100 w-100" style={{ marginTop: "-70px" }}>
          <Col md="4" xl="4">
            <Card className="p-4">
              <Card.Body>
                <h3 className="text-center">Sign In</h3>
                <p className="text-center">Sign in to stay connected</p>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" className="mb-0" placeholder="Enter email" value={formData.email} onChange={handleInputChange} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" className="mb-0" placeholder="Enter password" value={formData.password} onChange={handleInputChange} />
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Check className="d-inline-block mt-2 pt-1">
                      <FormCheck.Input type="checkbox" id="customCheck11" />
                      <FormCheck.Label htmlFor="customCheck11">Remember Me</FormCheck.Label>
                    </Form.Check>
                    <Link to="#">Forget password</Link>
                  </div>
                  <div className="text-center pb-3">
                  {isLoading  ? (
  <Button variant="primary" disabled={true}>
    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
    Loading...
  </Button>
) : (
  <Button type="submit" disabled={!isFormValid}>Sign in</Button>

)}
                  </div>
                </Form>
                <p className="text-center">Don't have an account?<Link > Contact Youree to sign up.</Link></p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Fragment>
  )
})

SignIn.displayName = "SignIn"
export default SignIn;
