import { request } from "../../axios"; // Replace with equivalent library for making HTTP requests in React
// import { request } from "../../axios"; // Replace with equivalent library for making HTTP requests in React
// import { useDispatch, useSelector } from 'react-redux' // Replace with equivalent state management solution for React
// import Config from "dotenv"; // Replace with equivalent library for environment variables in React

export const http = (store) => (next) => async (action) => {
  console.log("from middleware",action)
  // const dispatch = useDispatch()
  if (action.payload && action.payload.url) {
    const { method, url, params, body, header } = action.payload;

    let config = {
      method,
      url,
      params,
    };

    body && (config = { ...config, data: body });
    header && (config = { ...config, headers: header });

    store.getState().account.token &&
      (request.defaults.headers.common.Authorization = "Bearer ".concat(
        store.getState().account.token
      ));

    // multipart queries
    if (header && header["Content-Type"] === "multipart/form-data") {
      const bodyFormData = new FormData();

      Object.keys(body).map((key) => {
        bodyFormData.append(key, body[key]);
      });

      config = { ...config, data: bodyFormData };
    }

    try {
      action.result = await request(config);
    } catch (error) {
      action.error = error;
    }
  }


  return next(action);
};
