import React from 'react';
import { Modal, Card, Tab, Image, Button } from 'react-bootstrap';
import enedisLogo from "../../../assets/images/logo/Logo_enedis.png";

function ChargingAdressModal({ isModalVisible, setIsModalVisible }) {
  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal show={isModalVisible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enedis</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
          <Tab.Container defaultActiveKey="first">
            <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
              <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                <div className="table-responsive">
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Image className="img-fluid w-35 me-3" src={enedisLogo} alt="" style={{ borderRadius: "5px", width: "97%" }} />

                    <div style={{ marginTop: "2%" }}>
                      <p style={{ fontSize: "19px", color: "black" }}>
                        Enedis is responsible for managing the public electricity distribution network up to the electricity meter in most of mainland France.
                      </p>
                      <p style={{ fontSize: "19px", color: "black" }}>
                        To help balance the electrical network, Enedis requests permission to transmit Linky data to us.
                      </p>
                      <p style={{ fontSize: "19px", color: "black" }}>
                        By clicking the "Login" button, you can access your personal Enedis account and grant consent for your data to be transmitted.
                      </p>
                    </div>

                   
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          
        
        <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
                      <Button style={{width :"30%"}}>Login</Button>
                    </div>
      </Modal.Body>
    </Modal>
  );
}

export default ChargingAdressModal;
