import React, { useState, useEffect, memo, Fragment} from 'react';
import { Row, Col, Dropdown, Button, Form, Table, Card, Tab, Nav, Navbar, Container, NavDropdown, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as SettingSelector from '../../store/setting/selectors';
import { setSelectedDriver,setAllDrivers } from '../../store/Account/accountSlice';
import { editCars } from '../../store/Car/carSlice';
import car from "../../assets/images/cars/Tesla-Model-3.png";
import {updateDriver} from '../../store/Account/actions'
import {updateVehicle} from '../../store/Car/actions'

const Drivers = memo((props) => {
  const allDrivers = useSelector((state) => state.account.allDrivers);
  const themeSchemeDirection = useSelector(SettingSelector.theme_scheme_direction);

  useSelector(SettingSelector.theme_color);
  const navigate = useNavigate();
    const dispatch = useDispatch();
  const getVariableColor = () => {
    let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
    const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
    const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
    const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const { allCars } = useSelector((state) => state.car);
  const variableColors = getVariableColor();
  const [filteredResults, setFilteredResults] = useState([]);
  const [filtredDrivers,setFiltredDrivers] = useState(allCars);
  const [searchInput, setSearchInput] = useState('');
  const [show, setShow] = useState(false);
  const [filtredEVs,setFiltredEVs] = useState(allCars.filter((car)=>car.currentUser ===null))
  const [selectedDriverId,setSelectedDriverId] = useState({});

  const colors = [variableColors.primary, variableColors.info];
  
  useEffect(() => {
    return () => colors;
  }, [colors]);

  useEffect(() => {
    setFilteredResults(allDrivers);
    
  }, [allDrivers]);
  
  useEffect(() => {
    setFiltredEVs(allCars.filter((car)=>car.currentUser ===null))    
  }, [allCars]);

  const selectVehicle = (driver) => {
    dispatch(setSelectedDriver(driver))
    navigate(`/driver/${driver.id}`);
  };

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== '') {
      const filteredData = allDrivers.filter((item) => {
        return Object.values(item.firstName + ' ' + item.lastName).join('').toLowerCase().includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(allDrivers);
    }
  };
  const assignCarToDriver = (car) => {
  //update the drivers
    
const newDriver = { ...selectedDriverId };
newDriver.currentVehicule = car;
// newDriver.assignedId = car.id;
// newDriver.assigned = car.name;
setSelectedDriverId(newDriver);
const driverIndex = allDrivers.findIndex(driver => driver.id === selectedDriverId.id);
const newAllDrivers = [...allDrivers]; // Create a new array with allDrivers elements

newAllDrivers[driverIndex] = newDriver;
dispatch(setAllDrivers(newAllDrivers));
setShow(false);
dispatch(editCars({car,driver : newDriver}));

const driverid = newDriver.id;
// let updatedVehicle = car;
let updatedCar = allCars[allCars.findIndex(vehicle => vehicle.id === car.id)];


// Create a new object with the updated 'currentUser' property
const updatedVehicle = {
  ...updatedCar,
  currentUser: newDriver,
};



dispatch(updateDriver({             
   onSuccess: (result) => {
 const vehicleId = car.id;
 dispatch(updateVehicle({
  onSuccess: (result) => {

  },
  vehicleId,
  updatedVehicle,
  onFailure: (e) => {
    console.log(e,"failed to update Vehicle")
   }
 
 }))
 
},
driverid,
newDriver,
onFailure: (e) => {
 console.log(e,"failed to update driver")
}
}))



  };
  const handleAssignButton =  (driver) => {
    setSelectedDriverId(driver);
    setShow(true);
};  
const filterDrivers =(filterType)=>{
if (filterType === "hasEv") {
  setFilteredResults(allDrivers.filter(driver => driver.currentVehicule !== null));
}
if (filterType === "noEv") {

  setFilteredResults(allDrivers.filter(driver => driver.currentVehicule === null));

}
else {
  setFilteredResults(allDrivers);
}
};
const handlePercentage = (percentage) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const formattedPercentage = formatter.format(percentage);

  return formattedPercentage;
};
  return (
    <Fragment>
      <h3>Drivers</h3>
      <Navbar bg="white" expand="lg" className="navbar-light">
        <Container fluid>
          <Navbar.Collapse className="collapse" id="navbarSupportedContent">
            <Nav className=" me-auto mb-2 mb-lg-0">
              <NavDropdown title="Filter" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#" onClick={()=>filterDrivers("hasEv")}>Assigned</NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={()=>filterDrivers("noEv")}>Not Assigned</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#" onClick={()=>filterDrivers("all")}>All drivers</NavDropdown.Item>

              </NavDropdown>
            </Nav>
            <div className="d-flex">
              <input
                className="form-control me-5"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => searchItems(e.target.value)}
              />
              <Button
                className="btn btn-outline-dark bg-primary"
                style={{ width: '80%', backgroundColor: '#3B44F6' }}
                onClick={() => navigate('/add-driver')}
              >
                Add Driver
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Col lg="60" md="12">
        <Card className="card-block">
          <Tab.Container defaultActiveKey="first">
            <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
              <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                <div className="table-responsive">
                  <table id="basic-table" className="table mb-0 table-striped" role="grid">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6 className="mb-0">Drivers</h6>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6 className="mb-0">Email</h6>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6 className="mb-0">Position</h6>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6 className="mb-0">Assigned Vehicle</h6>
                          </div>
                        </td>
                      </tr>
                      <div className="tab-bottom-bordered"></div>
                      {filteredResults?.map((driver,index) => (
                        <tr>
                          <td>
                            <div style={{cursor :"pointer"}} className="d-flex align-items-center">

                                {/* <img alt="driver" src={driver.profilPic}/> */}
                                <h6 className="mb-0" onClick={()=>selectVehicle(driver)}>
                                  {driver.firstName + ' ' + driver.lastName}
                                </h6>
                           
                            </div>
                          </td>
                          <td className="text-dark">{driver.email}</td>
                          <td className="text-dark">{driver.position}</td>
                          <td className="text-dark " >
                          <div className="d-flex align-items-center">
  {
    !driver.currentVehicule ?(
     <td> <u className='text-primary' style={{cursor:"pointer"}} onClick={()=>handleAssignButton(driver)}>Assign</u></td>

    ):(
      <div className="d-flex align-items-center"  >
<h6 className="mb-0">{ driver.currentVehicule.make+" " + driver.currentVehicule.model}</h6>
</div>
      
    )

  }
</div>
                          
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </Col>
      <Offcanvas  style={{width : "35%"}} show={show} onHide={() => setShow(false)} placement={`${themeSchemeDirection === "rtl" ? 'start' : 'end'}`} backdrop={false} scroll={true} className="live-customizer">
                <Offcanvas.Header closeButton>
               
                </Offcanvas.Header>
                <Offcanvas.Body>
                <h3 style={{marginLeft:"10px"}}>Unassigned EVs</h3>

                    <Row>
                     
                        <Col md={12}>
                            <div className="border border-2 rounded">
                            <Tab.Container defaultActiveKey="first">
                        
                        <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                            <Tab.Pane eventKey="first" id="payment" role="tabpanel" aria-labelledby="payment">
                                <div className="table-responsive">
                                    <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                        <tbody>
                                        <tr>
                                            <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Vehicles</h6>
                                                    </div>
                                                </td>
                                                
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Plate Number</h6>
                                                    </div>
                                                </td>
                                                
                                                
                                              
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0">Brand</h6>
                                                    </div>
                                                </td>

                                                
                                            </tr>
                                            <div className="tab-bottom-bordered"></div>
                                            {filtredEVs.map((vehicle)=>(
                                             
  <tr >
    
  <td>
          <div className="d-flex align-items-center">
          <img src={vehicle.imgUrl} style={{ height: "30px", width: "50px",marginTop:"2px",cursor:"pointer" }} onClick={()=>assignCarToDriver(vehicle)}/>
          <Col>
          <h6 className="mb-0">{vehicle.assigned}</h6>
          <span className={`badge rounded-pill`} style={{backgroundColor:` ${
                                                                 vehicle.soc >= 0.8 && vehicle.chargingStatus!=="Charging" ? '#1cd90d'
                                                                : vehicle.soc >= 0.5 && vehicle.chargingStatus!=="Charging" ? '#6103f2'
                                                                : vehicle.chargingStatus==="Charging" ? '#40454d'
                                                                :  '#ba4d42'
                                                                }`}}>{handlePercentage(vehicle.soc)}</span>


          </Col>

          </div>
      </td>
      
      <td>
          <div className="d-flex align-items-center">
              <h6 className="mb-0">{vehicle.plateNumber}</h6>
          </div>
      </td>
      
      
    
      <td>
          <div className="d-flex align-items-center">
              <h6 className="mb-0">{vehicle.make+" "+vehicle.model}</h6>
          </div>
      </td>

      
  </tr>
                                            ))}
                                          

                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                         
                           
                        </Tab.Content>
                    </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
    </Fragment>
  );
});

Drivers.displayName = 'Drivers';
export default Drivers;
