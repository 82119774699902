import React, { useState } from 'react';
import VizualisationComponent from './VizualisationComponent';


const CarbonfootprintByMake = () => { 
  
  const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'Energy analytics',
            content: <VizualisationComponent url="https://prod-uk-a.online.tableau.com/t/youreefrance/views/Reports-Energyanalytics/Energyanalytics" />
        },
        {
            id: 2,
            tabTitle: 'Carbon analytics',
            content: <VizualisationComponent url="https://prod-uk-a.online.tableau.com/t/youreefrance/views/Reports-Energyanalytics/Carbonanalytics" />
        }
    ];

const handleTabClick = (e) => {
  setCurrentTab(e.target.id);
}

  return (
    <div style={{ width: "80%", margin: 0 }}>
            <div className='tabs' style={{ display: "flex", justifyContent: 'space-between'}}>
                {tabs.map((tab, i) =>
                    <button style={currentTab === `${tab.id}` ? {backgroundColor:'lightgray', border: 'none', color: 'black', cursor: 'pointer', padding: '25px', width: '80%'}
                     : { backgroundColor:'lightgray', border: 'none', color: 'gray', cursor: 'pointer', padding: '25px', width: '80%' }}
                    key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                )}
            </div>
            <div className='content'>
                {tabs.map((tab, i) =>
                    <div key={i}>
                        {currentTab === `${tab.id}` && <div><p className='title' style={{ color: 'black', marginBottom: '20px', fontSize: '20px'}}>{tab.title}</p><p>{tab.content}</p></div>}
                    </div>
                )}
            </div>
        </div>
  )

};

export default CarbonfootprintByMake;