import React, { useState,useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import SelectBrand from "../multiStepsModal/step1";
import SelectModel from "../multiStepsModal/step2";
import axios from 'axios';

function MultiStepModal({isModalVisible, setIsModalVisible}) {
  const [step, setStep] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [generatedLink, setGeneratedLink] = useState(null);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const brands = [
    { key: 'toyota', name: 'Toyota', logoUrl: 'https://www.carlogos.org/logo/Toyota-logo.png', models: ['Camry', 'Corolla', 'RAV4', 'Prius', 'Highlander'] },
    { key: 'honda', name: 'Honda', logoUrl: 'https://www.carlogos.org/logo/Honda-logo.png', models: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Odyssey'] },
    { key: 'ford', name: 'Ford', logoUrl: 'https://www.carlogos.org/logo/Ford-logo.png', models: ['F-150', 'Mustang', 'Explorer', 'Escape', 'Focus'] },
    { key: 'chevrolet', name: 'Chevrolet', logoUrl: 'https://www.carlogos.org/logo/Chevrolet-logo.png', models: ['Silverado', 'Camaro', 'Equinox', 'Tahoe', 'Malibu'] },
    { key: 'nissan', name: 'Nissan', logoUrl: 'https://www.carlogos.org/logo/Nissan-logo.png', models: ['Altima', 'Sentra', 'Rogue', 'Pathfinder', 'Maxima'] },
    { key: 'mercedes', name: 'Mercedes-Benz', logoUrl: 'https://www.carlogos.org/logo/Mercedes-Benz-logo.png', models: ['C-Class', 'E-Class', 'S-Class', 'GLC', 'GLE'] },
    { key: 'bmw', name: 'BMW', logoUrl: 'https://www.carlogos.org/logo/BMW-logo.png', models: ['3 Series', '5 Series', 'X3', 'X5', '7 Series'] },
    { key: 'volkswagen', name: 'Volkswagen', logoUrl: 'https://www.carlogos.org/logo/Volkswagen-logo.png', models: ['Golf', 'Jetta', 'Passat', 'Tiguan', 'Atlas'] },
    { key: 'audi', name: 'Audi', logoUrl: 'https://www.carlogos.org/logo/Audi-logo.png', models: ['A4', 'A6', 'Q5', 'Q7', 'TT'] },
    { key: 'kia', name: 'Kia', logoUrl: 'https://www.carlogos.org/logo/Kia-logo.png', models: ['Optima', 'Soul', 'Sportage', 'Sorento', 'Forte'] },
    { key: 'mazda', name: 'Mazda', logoUrl: 'https://www.carlogos.org/logo/Mazda-logo.png', models: ['Mazda3', 'Mazda6', 'CX-5', 'CX-9', 'MX-5 Miata'] },
    { key: 'subaru', name: 'Subaru', logoUrl: 'https://www.carlogos.org/logo/Subaru-logo.png', models: ['Outback', 'Forester', 'Impreza', 'Legacy', 'WRX'] },
    { key: 'hyundai', name: 'Hyundai', logoUrl: 'https://www.carlogos.org/logo/Hyundai-logo.png', models: ['Elantra', 'Sonata', 'Tucson', 'Santa Fe', 'Kona'] },
    // Add more brands and models as needed
];
  const handleNext = () => {
    setStep(step + 1);
    if (step === 2) {
      handleSelectBrand()
    }
  };

  const handleBack = ()=> {
    setStep(step - 1)
  }

  const handleClose = () => {
    setStep(1);
    setIsModalVisible(false)
  };
  const handleSelectBrand = (brand) => {
console.log(brand)    
    axios.get(`https://console-youree-dev.azurewebsites.net/api/Youree/user/enode/link-car?userId=Aaa&brand=${brand.name.toUpperCase()}&language=FR`)
    .then(res => {
            console.log(res)
            setGeneratedLink(res?.data?.data?.linkUrl)
            setButtonIsLoading(true)
          })
    .catch(err => {
              console.log(err)
            })
  }
  useEffect(() => {
    if (step === 1) {
          setSelectedBrand(null)
        }
        if (step === 2) {
          handleSelectBrand(selectedBrand)
        }
  },[step])

  return (
    <Modal show={true} onHide={handleClose}  >
      <Modal.Header closeButton >
        <Modal.Title>Connect your car with Enode</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        {step === 1 && (
          <div>
            <SelectBrand selectedBrand={selectedBrand} setSelectedBrand = {setSelectedBrand} brands={brands}/>
            <div className="d-flex justify-content-center">

            <Button onClick={handleNext} disabled={!selectedBrand}>Next</Button>
            </div>
          </div>
        )}
        {step === 2 && (
          <>
            <div className="d-flex justify-content-center h-500">
            <Button onClick={handleBack} className="mx-2">Back</Button>
            {generatedLink === null ? (
  <Button variant="primary" disabled={true}>
    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
    Loading...
  </Button>
) : (
  <Button onClick={() =>{ window.open(generatedLink, '_blank');setIsModalVisible(false)}}>
    open
  </Button>
)}

         

    </div>
          </>
        )}

      </Modal.Body>
    </Modal>
  );
}

export default MultiStepModal;
