import React, { useState, Fragment } from 'react';
import { Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as SettingSelector from '../../store/setting/selectors';
import ChargingAdressModal from '../modals/chargingAdressModal';
import ChargingAddressCard from './chargingAddressForm';

const EnergyInfos = () => {
  const themeSchemeDirection = useSelector(SettingSelector.theme_scheme_direction);
  const chargingAddresses = useSelector(state => state.account.chargingAddresses);

  const [addressesData,setAddressData] = useState(chargingAddresses);
  const [enedisVisible, setenedisVisible] = useState(false);
  const variableColors = getVariableColor();

  const addAddress = () => {
    setAddressData([
      {
        name: "",
        contractType: "fixed",
        peakCost: {
          value: "0.0",
          unit: "€/kWh"
        }
      },
      ...addressesData
    ]);
  };
  

  return (
    <Fragment>
      {enedisVisible && <ChargingAdressModal isModalVisible={enedisVisible} setIsModalVisible={setenedisVisible} />}
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-around', width: '80%', marginLeft: '10%', marginTop: '2%', marginBottom: "20px" }}>
        <h4>Settings &gt; <u>Energy Infos</u></h4>
        <div style={{ display: 'flex', alignItems: 'end', width: "40%", justifyContent: 'center' }}>
          <div style={{ backgroundColor: variableColors.primary, border: '0px solid #000', borderRadius: '5px', cursor: 'pointer', padding: "3px" }} onClick={() => setenedisVisible(true)}>
            <h5 style={{ color: "white", margin: "3px" }}>Connect to Enedis</h5>
          </div>
          <div style={{ backgroundColor: "white", border: '0px solid #000', borderRadius: '5px', cursor: 'pointer', padding: "3px" }} onClick={addAddress}>
            <h5 style={{ margin: "3px" }}>+ Add charging address</h5>
          </div>
        </div>
      </div>

      <Row className="justify-content-center" lg="8" md="12">
        <Col lg="8" md="12">
          {addressesData?.map((address, index) => (
            <ChargingAddressCard key={index} data={address} variableColors={variableColors}   />
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};



const getVariableColor = () => {
  let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
  if (prefix) {
    prefix = prefix.trim();
  }
  const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
  const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
  const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
  const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
  return {
    primary: color1.trim(),
    info: color2.trim(),
    warning: color4.trim(),
    primary_light: color3.trim(),
  };
};

EnergyInfos.displayName = "EnergyInfos";
export default EnergyInfos;
